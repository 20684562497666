export default {
  "TOP": "返回顶部"

  , "Head.Admin": "管理后台"
  , "Head.Mine": "个人信息"
  , "Head.Lecturer": "讲师专区"
  , "Head.Logout": "退出登录"
  , "Head.Home": "首页"
  , "Head.SelecLanguage": "选择语言"

  , "Home.LearningDuration": "学习时长"
  , "Home.Tasks": "我的任务"
  , "Home.Course": "课程"
  , "Home.Project": "项目"
  , "Home.Exam": "考试"
  , "Home.SeeMore": "查看更多"
  , "Home.todoTips": "即将开始/进行中的考试、面授和直播"
  , "Home.pcs": "个"
  , "Home.reload": "刷新"
  , "Home.init": "首页数据初始化中，请稍等"
  , "Home.LearningDurationRanking": "学习时长排行榜"
  , "Home.AllRankings": "全部排行榜"
  , "Home.TodayLeaderboard": "今日排行榜"
  , "Home.WeekLeaderboard": "本周排行榜"
  , "Home.MonthLeaderboard": "本月排行榜"
  , "Home.WaitingInVain": "虛位以待"
  , "Home.inProgress": "进行中"
  , "Home.beginInAMinute": "即将开始"
  , "Home.ExamFrequency": "考试次数"
  , "Home.noLimit": "不限"
  , "Home.NumberOfParticipants": "参与人数"
  , "Home.FromEnd": "距结束"
  , "Home.FromStart": "距开始"
  , "Home.day": "天"
  , "Home.EnterImmediately": "立即进入"
  , "Home.NoIntroduction": "暂无简介"
  , "Home.NoLecturer": "暂无讲师"
  , "Home.introduce": "介绍"
  , "Home.None": "暂无"
  , "Home.NoContent": "暂无内容"
  , "Home.Newly": "新上架"
  , "Home.NoData": "没有更多内容了"
  , XB_PlatformSwitching: "平台切换",
  XB_LarkLogin: "飞书登录",
  XB_DingTalkLogin: "钉钉登录",
  XB_WeComLogin: "企业微信登录",
  XB_WeComH5Login: "企业微信H5登录",
  XB_WechatLogin: "微信登录",
  XB_PersonalRegistration: "个人注册",
  XB_ExamAnswers: "考试答题",
  XB_LearnMapDetails: "学习地图详情",
  XB_LiveBroadcastRoom: "直播间",
  XB_UserAgreement: "用户协议",
  XB_ErrorPage: "错误页面",
  XB_LoginPrompt: "登录提示",
  XB_Preview: "预览",
  XB_Unauthorized: "未获得许可",
  XB_Study: "学习",
  XB_Resource: "资源",
  XB_ResourceCenter: "资源中心",
  XB_MyData: "我的知识",
  XB_NotFinishedLearning: "未学完",
  XB_PersonalCenter: "个人中心",
  XB_PopularKnowledge: "热门知识",
  XB_LatestCourses: "最新课程",
  XB_LearningTask: "学习任务",
  XB_KnowledgeBase: "知识库",
  XB_KnowledgeDetail: "知识详情",
  XB_QuestionnaireSurvey: "调查问卷",
  XB_CourseCenter: "课程中心",
  XB_PagesNumber: "页数",
  XB_ClearCache: "清除缓存",
  XB_StudyHoursRanking: "学时排行",
  XB_HasEnded: "已结束",
  XB_Search: "搜索",
  XB_My_CompleteCourse: "完成课程",
  XB_My_GetCredit: "获得学分",
  XB_Home_LastLearned: "上次学到",
  XB_Home_EnterLearning: "进入学习",
  XB_Home_SeeMore: "查看更多",
  XB_More: "更多",
  XB_Home_ClickView: "点击查看",
  XB_Home_Uptime: "上线时间",
  XB_Home_Instructor: "授课老师",
  XB_Home_Browse: "浏览",
  XB_Home_ReadingVolume: "阅读量",
  XB_Home_To: "至",
  XB_LP_Stage: "第 {0} 阶段",
  XB_LP_StudentStudiedTogether: "共 {0} 名学员一同学习",
  XB_LP_MyRankingIs: "我的排名是 {0}",
  XB_LP_DefeatedTheStudents: "打败了 {0} 的学员",
  XB_PleaseAnswerTheQuestionFirst: "请回答下面的问题后继续学习",
  XB_AnswerTheQuestionFirst: "您已学习{0}分钟，回答下面的问题后继续学习",
  XB_WarmTip: "温馨提示",
  XB_ContinueToWatchTheCourse: "恭喜您答对了,请继续观看课程",
  XB_WrongSwitchNextQuestion: "此题答错啦,请点击确认切换下一题",
  XB_NumberOfParticipants: "参与人数",
  XB_NumberOfLearners: "学习人数",
  XB_Reward: "赏",
  XB_GetRewardedScores: "共获得打赏积分",
  XB_InTotal: "共 {0} 条",
  XB_AvailableContent: "可获内容",
  XB_AvailableHours: "可获学时",
  XB_GetCredits: "可获学分",
  XB_QuestionBank: "题库",
  XB_Anonymous: "匿名",
  XB_Category: "类别",
  DR_Error_NoAuthorized: "对不起，您没有参加当前考试的权限",
  DR_Error_NoAuthorizedByExercise: "对不起，您没有参加当前练习的权限",
  XB_EntryType: "录入类型",
  XB_Type: "类型",
  XB_LearningDuration: "学习时长",
  XB_ThematicDiscussionGroup: "专题讨论组",
  XB_Personnel: "人员",
  XB_EnterDiscussionGroup: "进入讨论组",
  XB_ForwardYourAnswer: "的提问期待你的解答",
  XB_Reply: "答复",
  XB_LetMeSay: "我来说两句...",
  XB_MyTotalIntegral: "我的总积分",
  XB_EarnPoints: "完成学习平台任务获得积分",
  XB_AcquisitionMethod: "获取方式",
  XB_ImageUpload: "图片上传",
  XB_AllColleges: "全部学院",
  XB_IntroductionInstructions: "介绍说明",
  XB_MyPoints: "我的积分",
  XB_CommoditySurplus: "商品剩余",
  XB_HasExchanged: "已兑换",
  XB_ExchangeQuantity: "兑换数量",
  XB_ConsumptionPoints: "消费积分",
  XB_PeopleHaveJoined: "{0}人已加入",
  XB_Group_GroupShortTitle: "这个小组还差一个标题了~",
  XB_Group_DescribeThisGroup: "来吧，给这个小组描述下吧...",
  XB_Group_TopicShortTitle: "这个话题还差一个标题了~",
  XB_Group_DescribeThisTopic: "来吧，给这个话题描述下吧...",
  XB_Signup: "报名",
  XB_ExamHasStarted: "考试已开始",
  XB_FaceSampling: "人脸采样",
  XB_Course_Lecturer: "讲师",
  XB_LearningSituation: "学习情况",
  XB_Message: "消息",
  XB_TotalOf: "共有{0}条",
  Pub_Msg_DDM: "确定要删除该成员？",
  Pub_Msg_DCUAJd: "确定取消该用户管理员权限？",
  Pub_Msg_DUUpA: "确定该用户升级成管理员？",
  Lab_Group_SetAdministrator: "设置管理员",
  Lab_Group_CancelAdministrator: "取消管理员",
  CM_Rule_ImageTopic: "图片话题",
  LB_Group_ErrorMsg_TagDuplicate: "重复标签",
  LB_Group_ErrorMsg_DescriptionMissing: "描述不能为空",
  LB_Group_SaveTag: "保存标签",
  LB_Group_CustomTag: "自定义标签",
  XB_Group_ChooseTagMax: "最多选择5个",
  XB_Group_ChooseLabel: "选择标签",
  XB_Exam_PendingApproval: "待批阅",
  BN_Comm_Completed: "完成",
  BN_Comm_Edit: "编辑",
  BN_Settings_CancelBinding: "解除绑定",
  CM_Affirm: "确认",
  CM_All: "全部",
  CM_AllCollections: "全部收藏",
  CM_AlreadySignToday: "今天已签到",
  CM_Answer: "已答",
  CM_Retract: "收起",
  CM_Current: "当前",
  CM_Correct: "答对",
  CM_Wrong: "答错",
  CM_Audite: "是否审核",
  CM_Btnreset: "重置",
  CM_Cancel: "取消",
  CM_CancelCollection: "取消收藏成功！",
  CM_CancelCollectionFailure: "取消收藏失败",
  CM_CancelFavoriteSuccess: "取消收藏成功",
  CM_CancelRegistration: "不可取消报名！",
  CM_CancelRegistrationSuccessful: "取消报名成功！",
  CM_CCondition: "完成条件",
  CM_Choose: "选修",
  CM_SignInNow: "立即签到",
  CM_CollectionFailure: "收藏失败",
  CM_CollectionSuccess: "收藏成功！",
  CM_Comment: "点评",
  CM_Completed: "已完成",
  CM_Compressed: "压缩文件",
  CM_Confirm: "确定",
  CM_ConfirmPassword: "确认新密码",
  CM_ContentLengthLong: "内容长度超出限制",
  CM_ConversionForSuccessful: "兑换成功！",
  CM_CT: "面授培训",
  CM_Curriculum: "课程",
  CM_CutNumber: "切屏次数或切屏时间超过限制,将强制交卷！",
  CM_DailyPointsRule: "每日签到可获得{0}积分",
  CM_Delete: "删除",
  CM_Department: "部门",
  CM_DepartmentFillUp: "您所属的部门报名人数已满！",
  CM_Detail: "详细",
  CM_Empty: "空空如也",
  CM_EmptyPassword: "密码不能为空!",
  CM_EndTime: "结束时间",
  CM_EnterNewPassword: "输入新密码",
  CM_EnterOriginalPassword: "输入原始密码",
  CM_EnterQues: "提问",
  CM_EverythingIsShown: "已显示所有内容",
  CM_PullUpLoadMore: "上拉加载更多",
  CM_Exam_CutChance: "请注意，切屏次数已用完，再切屏将会强制交卷!",
  CM_Exchange: "换一换",
  CM_Exist: "页面上存在未参与的调查项！",
  CM_Expired: "已过期",
  CM_FaceToFaceName: "面授名称",
  CM_Fail: "操作失败",
  CM_FailDelete: "删除失败！",
  CM_FailUpload: "上传失败！",
  CM_FailureEnroll: "报名失败！",
  CM_FillUp: "报名人数已满！",
  CM_FinInscriptions: "报名结束！",
  CM_Finish: "完成",
  CM_Finished: "已完成",
  CM_GetScore: "得分",
  CM_GetSuccess: "领取成功！",
  CM_HadNotSubmit: "未提交",
  CM_HadSubmit: "已提交",
  CM_HasSignedUp: "已经报名！",
  CM_Header: "头像",
  CM_Hour: "小时",
  CM_Index: "首页",
  CM_IngenKlassificering: "页面上存在未评分的项，请为所有评分项评分！",
  CM_InitiationTask: "学习任务已开始，不能取消报名！",
  CM_IsSubmitted: "提交中...",
  CM_Join: "参加",
  CM_LB_AttachmentFile: "附件",
  CM_LB_Credit: "学分",
  CM_LB_Description: "描述",
  CM_LB_Name: "名称",
  CM_LB_Progress: "进度",
  CM_LB_Prompt: "提示",
  CM_LB_Reviews: "评语",
  CM_LB_Save: "保存",
  CM_LB_Status_Null: "暂无",
  CM_LB_SubmitTime: "提交时间",
  CM_MyStudy: "我的学习",
  CM_Level: "等级",
  CM_Live: "直播",
  CM_Medal: "勋章",
  CM_Minute: "分钟",
  CM_MobilePhone: "手机号码错误！",
  CM_ModifyPassword: "修改密码",
  CM_MostComments: "评论最多",
  CM_Name: "名称",
  CM_NameSorting: "名称排序",
  CM_Newest: "最新",
  CM_NoAnswer: "未答",
  CM_NoJoin: "未参加",
  CM_NoPass: "未通过",
  该考试设置了仅看错题答对的题目将不在此显示:"该考试设置了仅看错题",
  CM_NoRight: "您没有部门报名范围控制权限！",
  CM_NoTakePartIn: "未参与",
  CM_NotCompleted: "未完成",
  CM_NotLearning: "未学习",
  CM_OK: "确定",
  CM_Open: "开启",
  CM_Pass: "通过",
  CM_PassStatus: "通过状态",
  CM_Period: "学时",
  CM_Piece: "件",
  CM_PleaseEnterContent: "请输入内容",
  CM_Points: "分",
  CM_PopularityRanking: "人气排序",
  CM_PraiseMost: "点赞最多",
  CM_Public: "公开",
  CM_QA: "问答题",
  CM_QAAnswer: "回答",
  CM_QRCode: "二维码",
  CM_Refuse: "拒绝",
  CM_RepeatRegistration: "已报名,不能重复报名！",
  CM_Reply: "回复",
  CM_Required: "必修",
  CM_Rule_BestTopic: "精华话题",
  CM_Rule_CancelBest: "取消精华",
  CM_Rule_CancelTop: "取消置顶",
  CM_Save: "保存",
  CM_SearchCurriculumData: "搜索课程&知识",
  CM_Second: "秒",
  CM_Select: "请选择",
  CM_SelectAll: "全选",
  CM_ServiceExperience: "服务经历",
  CM_SignFail: "签到失败",
  CM_SignIn: "登录",
  CM_SignOutFail: "签退失败",
  CM_SignOutSuccess: "签退成功",
  CM_SignRules: "签到规则",
  CM_SignSuccess: "签到成功",
  CM_Signup_NotAllow: "您所属部门不允许报名！",
  CM_Skip: "跳过",
  CM_SorryCommentsTooFast: "对不起，您发表评论的频率过快",
  CM_SortSort: "类别排序",
  CM_StartedYet: "报名还未开始！",
  CM_StartTime: "开始时间",
  CM_Status: "状态",
  CM_StudyMode_Map: "路径图",
  CM_Submit: "提交",
  CM_SubmitFail: "提交失败",
  CM_SubmitTime: "提交时间",
  CM_Success: "操作成功！",
  CM_SuccessfullyDelete: "删除成功！",
  CM_Survey: "问卷",
  CM_TakePartIn: "已参与",
  CM_Task: "任务",
  CM_ThumbUpSuccess: "点赞成功！",
  CM_TimeDue: "取消报名时间到期！",
  CM_TimeIsNew: "时间最新",
  CM_Tip: "提示",
  CM_UnExpired: "未过期",
  CM_Unfinished: "未完成",
  CM_Unlimited: "不限",
  CM_UnSelectAll: "全不选",
  CM_UploadAttachment: "请上传附件后再提交！",
  CM_UserName: "用户名",
  CM_UserPassWord: "密码",
  CM_ViewResult: "查看结果",
  CM_WorkExperience: "工作经历",
  DR_Error_CourseIsEnd: "课程已结束",
  DR_Error_CourseIsNotBegin: "课程未开始",
  DR_Error_CourseIsNotCanStudy: "暂时不能学习",
  DR_Error_CourseIsPrecondition: "课程存在前置条件",
  DR_Error_CourseNeedSignUp: "课程未报名",
  DR_Error_CourseNotExists: "无任何课程信息",
  DR_Error_CourseStudyPermissionError: "对不起，您没有当前课程的学习权限",
  DR_Error_CTIsConNotStudy: "此面授未授权",
  DR_Error_DocumentCannotView: "无法查看当前格式的文档",
  DR_Error_EndTimeFormatError: "结束时间格式错误",
  DR_Error_JsonFormatError: "输入的字符串格式不正确",
  DR_Error_SearchParamConvertError: "搜索参数实体转换错误",
  DR_Error_SearchParamIsEmpty: "搜索参数实体对象不能为空",
  DR_Error_StartTimeFormatError: "开始时间格式错误",
  KM_HaveRead: "已阅读",
  KM_NotFinished: "未读完",
  KM_Unread: "未阅读",
  Lab_ChooseLanguage: "请选择以下语言：",
  Lab_CourseDetail_Collect: "收藏",
  Lab_CourseDetail_CourseDetail: "课程详情",
  Lab_Doc_JoinEvaluate: "参与评估",
  Lab_Evaluate_M_BaseInfo: "培训基本信息",
  Lab_Evaluate_T_CanMulti: "可多选",
  Lab_Evaluate_T_Course: "培训课程：",
  Lab_Evaluate_T_FeedbackInfo: "培训反馈信息",
  Lab_Evaluate_T_Teacher: "培训教师：",
  Lab_Evaluate_T_TrainingAddress: "培训地点：",
  Lab_Evaluate_T_TrainingDate: "培训时间：",
  Lab_Evaluate_T_WebCastTime: "直播时间：",
  Lab_Exam_UserScore: "{0} 分",
  Lab_ExamDetail_ReExamNum: "补考次数",
  Lab_ExamDetail_ViewResult: "查看答卷",
  Lab_Group_AllTopic: "所有话题",
  Lab_Group_ExitGroup: "退出小组",
  Lab_Group_GroupLeader: "组长",
  Lab_Group_HotGroup: "热门小组",
  Lab_Group_MyGroup: "我的小组",
  Lab_Group_NewestTopic: "最新话题",
  Lab_Group_NotOpenToNonMembers: "隐私（该小组内的话题不对非小组成员公开）",
  Lab_Group_TopicList: "话题列表",
  Lab_Homework_InputContent: "请输入作业内容...",
  Lab_Homework_Submit: "提交作业",
  Lab_Knowledge_HotSearch: "热门搜索",
  Lab_KnowledgeArticleDetail_Publish: "发表",
  Lab_Lesson_AllNumber: "全部人数",
  Lab_Lesson_CorrectNumber: "答对人数",
  Lab_Lesson_CorrectRate: "正确率",
  Lab_Lesson_OpenState: "开启状态",
  Lab_Lesson_ParticipationPercentage: "参与百分比",
  Lab_Lesson_QuestionFull: "请将内容填写完整",
  Lab_Lesson_RightKey: "正确答案",
  Lab_Lesson_SubmitNumber: "提交人数",
  Lab_Lesson_YourAnswer: "您的答案",
  Lab_Lesson_YouRight: "恭喜您答对了!",
  Lab_Lesson_YouWrong: "很遗憾您答错了!",
  Lab_LessonDetail_T_LiveDetail: "直播详情",
  Lab_LessonDetail_T_SignIn: "签到",
  Lab_LessonDetail_T_SignOut: "签退",
  Lab_LessonInteract_Closing: "完成条件",
  Lab_LessonInteract_Photograph: "拍照",
  Lab_LessonInteract_SignIn: "签到",
  Lab_LessonInteract_SignOut: "签退",
  Lab_Mall_Conversion: "立即兑换",
  Lab_MyExam_ExaminationHistory: "历史考试",
  Lab_MyExam_InExamination: "正式考试",
  Lab_MyFavorite_CancelFavorite: "取消收藏",
  Lab_MyFavorite_FavoriteDate: "收藏时间",
  Lab_MyHonnor_Draw: "领取",
  Lab_MyIntegra_IntegralGrade: "积分等级",
  Lab_MyIntegra_T_GradeIntegral: "等级积分",
  Lab_MyIntegra_TotalScore: "总积分",
  Lab_MyIntegral_IntegralRule: "积分规则",
  Lab_MyIntegral_Onetime: "一次性  ",
  Lab_MyInteract_CommentDesc: "评价了",
  Lab_MyInteract_DiscussionGroup: "讨论组",
  Lab_MyInteract_MyComment: "我的评论",
  Lab_MyInteract_MyReplay: "我的回复",
  Lab_MyPlan_GetCredit: "获得学分",
  Lab_MySignUp_T_Ranking: "排名",
  Lab_News_Cancel: "取消",
  Lab_News_News: "新闻",
  Lab_Signup_Msg_Audit: "您的报名正在审核",
  Lab_Signup_Msg_Success: "您的报名已成功",
  Lab_SignUp_Status: "报名状态",
  Lab_Signup_T_CancelRegistration: "取消报名",
  Lab_Signup_T_GoSign: "我要报名",
  Lab_Subject_SynthesizeOrder: "综合排序",
  Lab_Teacher_Fen: "分",
  LB_AboutInstructor: "讲师介绍",
  LB_AccessibleIntegral: "可获积分",
  LB_Account_BindAnotherWeiXin: "当前绑定账号：{0}",
  LB_Account_EnterLogo: "请输入企业标识",
  LB_Account_InputAccount: "请输入用户名",
  LB_Account_InputPassword: "请输入密码",
  LB_AllPhotos: "全部照片",
  LB_AlreadyThumbsUp: "您已点过赞！",
  LB_Appraiser: "评估人",
  LB_AReward: "打赏",
  LB_Arrange: "安排",
  LB_AttachmentView: "附件查看，请到电脑端操作",
  LB_AudioFrequency: "音频",
  LB_AuditResult: "审核结果",
  LB_AutoBack: "{0}秒后自动返回",
  LB_Blend: "混合",
  LB_CancelPointPraiseSuccess: "取消点赞成功",
  LB_Cert_BeginLearn: "开始学习",
  LB_Cert_Certificate: "证书",
  LB_Cert_Finished: "已完成",
  LB_CertifiedCertificate: "可获证书",
  LB_Charge: "收费",
  LB_ChoseCourseCatalog: "选择你感兴趣的课程目录",
  LB_ClearRearchRecords: "清除搜索记录",
  LB_ClickUploadPhotos: "点击上传照片",
  LB_Coach_EnterName: "请输入名称",
  LB_Coach_SelectCategory: "选择类别",
  LB_Comment_ReplyTo: "对{0}的回复：",
  LB_Comment_SaySomething: "说点什么吧...",
  LB_CommentDeleted: "该评论已删除",
  LB_ConfirmationReward: "确认打赏",
  LB_Course_Cate: "目录",
  LB_Course_CourseDoc: "学习知识",
  LB_Course_FinalStudy: "最后学习",
  LB_Course_Goal: "课程目标",
  LB_Course_StartStudy: "开始学习",
  LB_Course_ToStudy: "进入学习",
  LB_Course_StudyProgress: "学习进度",
  LB_Course_TrainTarget: "培训对象",
  LB_CourseDetailPlay: "暂不支持播放，请前往PC端学习该课程",
  LB_Credit_CreditsOfTheMonth: "本月学分",
  LB_Credit_CreditThisYear: "本年学分",
  LB_Credit_TotalCredit: "总学分",
  LB_CT_AvailableCertificates: "完成课程可获得的证书",
  LB_CT_AvailableMedals: "完成课程可获得的勋章",
  LB_CT_CourseOutline: "课程大纲",
  LB_CT_FaceType: "面授类型",
  LB_CT_MemberNo: "暂无人员信息",
  LB_CT_NotSign: "未报名",
  LB_CurriculumFormat: "课程格式",
  LB_DepartmentRankings: "部门排行",
  LB_DetermineAssignment: "您确定要交卷吗？",
  LB_Doc_AddDescription: "添加描述",
  LB_Doc_Comments: "评论",
  LB_Doc_Confirm_Delete: "确定要删除吗",
  LB_Doc_Failure: "操作失败",
  LB_Doc_FavoriteSuccess: "收藏成功",
  LB_Doc_Mobile_Load: "加载中…",
  LB_Doc_Mobile_Screening: "筛选条件",
  LB_Doc_Source: "来源",
  LB_Doc_UploadTime: "上传时间",
  LB_DocumentConversionWait: "文档转换中，请稍后...",
  LB_EnterReward: "输入您要打赏的数值",
  LB_Error_ExamHasEnd: "考试已结束",
  LB_Error_ExamHasIng: "考试进行中",
  LB_Error_ExamHasTime: "后开始考试",
  LB_Error_SurveyNotBegin: "该问卷还未开始",
  LB_Error_SurveyNotExist: "该问卷不存在",
  LB_Error_SurveyNotView: "此问卷不许查看",
  LB_Error_SurveyOverdue: "该问卷已过期",
  LB_Error_SurveyPrepositionNot: "当前问卷有前置条件未完成",
  LB_Evaluate_Score: "分",
  LB_Exam_AllQuestion: "全部题目",
  LB_Exam_AnswerLessBanpaper: "答卷少于{0}分钟禁止交卷",
  LB_Exam_AreYouHand: "还有{0}道试题未答,确定要交卷吗",
  LB_Exam_BlankFilling: "填空题",
  LB_Exam_Casus: "案例题",
  LB_Exam_Cert: "证书",
  LB_Exam_CertStatus: "证书状态",
  LB_Exam_Countdown: "倒计时",
  LB_Exam_CutScreenNum: "切屏次数",
  LB_Exam_Detail: "考试详情",
  LB_Exam_EnterCounts: "参考次数",
  LB_Exam_ErrorQuestion: "答错题目",
  LB_Exam_EvaluationRules: "评卷规则",
  LB_Exam_ExamNum: "考试次数",
  LB_Exam_HandPaperReturnExam: "您已成功提交了答卷,将返回我的考试",
  LB_Exam_Judgement: "判断题",
  LB_Exam_LateControl: "迟到控制",
  LB_Exam_MultiSelect: "多选题",
  LB_Exam_NestQuestion: "下一题",
  LB_Exam_PassCondition: "及格条件",
  LB_Exam_QuestionAnalysis: "答题解析",
  LB_Exam_Radio: "单选题",
  LB_Exam_RightQuestion: "正确答案",
  LB_Exam_Secrecy: "保密",
  LB_Exam_StartExam: "开始考试",
  LB_Exam_SubPaperControl: "交卷控制",
  LB_Exam_TestTime: "考试时长",
  LB_Exam_ViewAnswerPaper: "查看答卷",
  LB_Exam_WellQuestion: "答对题目",
  LB_Examdhm: "距离考试开始还有 {0} 天 有 {1} 小时 有 {2} 分",
  LB_ExamRules: "考试规则",
  LB_Favorite_Cancel: "取消收藏",
  LB_Favorite_Score: "评分",
  LB_Favorite_SomePraise: "点赞",
  LB_Gene_Choose: "至少选择1个！",
  LB_Group_AddTag: "添加标签",
  LB_Group_AddTitle: "添加标题",
  LB_Group_CancelCollection: "确定取消收藏吗？",
  LB_Group_CanNotReward: "不允许给自己文章打赏",
  LB_Group_CoverPhoto: "封面图片",
  LB_Group_EditorialGroup: "编辑小组",
  LB_Group_Group: "小组",
  LB_Group_GroupHead: "小组头像",
  LB_Group_GroupMembers: "小组成员",
  LB_Group_GroupType: "小组类型",
  LB_Group_Hot: "热门",
  LB_Group_ICreated: "我创建的",
  LB_Group_IJoined: "我加入的",
  LB_Group_JoinGroup: "加入小组",
  LB_Group_NeedAudit: "加入需要审核",
  LB_Group_NewGroup: "新建组",
  LB_Group_NotNeedAudit: "加入不需审核",
  LB_Group_Publish: "发表",
  LB_Group_RewardArticle: "确定要打赏该文章吗？",
  LB_Group_RewardSuccess: "打赏成功！",
  LB_Group_SearchGroup: "搜索讨论组",
  LB_Group_SharingCode: "分享二维码",
  LB_HighestScore: "最高得分：{0} 分",
  LB_HistoricalRecord: "历史记录",
  LB_Picture: "图片",
  LB_Index_CT: "我的面授",
  LB_Integral_Deduction: "扣除",
  LB_Integral_Get: "获取",
  LB_Integral_MonthIntegral: "本月积分",
  LB_Integral_YearIntegra: "本年积分",
  LB_ISDeleteComment: "您确定要删除此条评论？",
  LB_IsExpired: "是否过期",
  LB_LearningRankings: "学习排行榜",
  LB_lecture_Star: "星级",
  LB_lesson_TeachingTime: "授课时间",
  LB_Level_DeductingIntegral: "扣除积分",
  LB_Level_GetIntegral: "获取积分",
  LB_Level_Score: "距升级还差{0}积分",
  LB_Live_EnterLive: "进入直播",
  LB_Live_LiveType: "直播类型",
  LB_Live_WaitLive: "请等待主讲人开始直播",
  LB_Live_WhetherParticipate: "是否参与",
  LB_Mall_AllGoods: "全部商品",
  LB_Mall_Exchange: "兑换",
  LB_Mall_ExchangeRecord: "兑换记录",
  Exchange_rules: "兑换规则",
  LB_Mall_GoToExchange: "去兑换",
  LB_Mall_ICanExchange: "我可兑换的",
  LB_Mall_Integral: "积分不够兑换",
  LB_Mall_People: "人已兑",
  LB_Mall_StillLeft: "还剩下",
  LB_Mall_Tips: "兑换后无法撤销,确定兑换？",
  LB_Manager_WhetherPass: "是否通过",
  LB_Map_DurationDays: "持续天数",
  LB_Map_SubDetails: "明细全部完成",
  LB_Marked: "已标记",
  LB_MedalAwarded: "可获勋章",
  LB_Message_SelectDeleteCollection: "请选择要删除的收藏",
  LB_Message_SelectDeleteComment: "请选择要删除的评论",
  LB_Message_SelectDeleteReply: "请选择要删除的回复",
  LB_Message_YouWantDeleteCollection: "确定要删除当前选中的收藏吗",
  LB_Message_YouWantDeleteComment: "确定要删除当前选中的评论吗",
  LB_Message_YouWantDeleteReply: "确定要删除当前选中的回复吗",
  LB_Minutes: "{0}分钟",
  LB_Msg_ConfirmDeleteSelect: "确定要删除当前选中的消息吗",
  LB_Msg_DeleteWithNoItem: "请选择要删除的消息内容",
  LB_Msg_Total: "共有{0}条消息",
  LB_My_Content: "我的内容",
  XB_MyManage: "我的管理",
  LB_My_Ge: "个",
  LB_My_GetCredit: "获得 {0} 学分",
  LB_My_HelpCenter: "帮助中心",
  LB_My_History: "历史",
  LB_My_LearningAchievements: "学习成就",
  LB_My_Mei: "枚",
  LB_My_Teaching: "授课",
  LB_My_Tiao: "条",
  LB_My_UpdateLog: "更新日志",
  LB_MyLearningState: "我的学习状态",
  LB_MyPhoto: "我的照片",
  LB_MyRanking: "我排在第{0}位",
  LB_Mysinup_Project: "项目",
  LB_News_NewsState: "新闻状态",
  LB_News_NumberOfVisitors: "浏览人数",
  LB_NoIntroduction: "暂无介绍",
  LB_NotCharge: "免费",
  LB_NotInRankings: "我还没有上榜",
  LB_NotListData: "暂无列表数据",
  LB_NotUploadClickUpload: "您还没有上传照片,{0}快来点击上传吧！",
  LB_NPAwardedCourse: "已有{0}人打赏该课程",
  LB_NumberWinners: "中奖人数",
  LB_NumPersonLearning: "{0}人学习",
  LB_Old_CT: "历史面授",
  LB_Period_TotalStudyTime: "总学习时长",
  LB_PersonScoring: "{0}人打分",
  LB_Photo: "照片",
  LB_PhotoNum: "照片数量",
  LB_PleaseQuestions: "请输入提问的内容",
  LB_Pro_ChangeLanguage: "语言切换",
  LB_Pro_DailyAttendance: "每日签到",
  LB_Pro_Di: "第{0}名",
  LB_Pro_Integral: "积分",
  LB_Pro_ObtainPoints: "获得{0}积分",
  LB_Pro_RankedAgainst: "你现在的排名打败了{0}的对手",
  LB_QA_Accepted: "被采纳",
  LB_QA_AddAnswer: "追答",
  LB_QA_AddQues: "追问",
  LB_QA_AdoptCurrent: "确定采纳当前回答",
  LB_QA_BeenDeleted: "该问题已删除",
  LB_QA_ChooseRewardValue: "选择悬赏值",
  LB_QA_DescribeDoubt: "请描述您的疑问（不能少于8个字）",
  LB_QA_DesIsNoe: "描述不能少于8个字",
  LB_QA_HotQuestion: "最热问题",
  LB_QA_IncreaseReward: "提高悬赏，更容易吸引高手为你解答",
  LB_QA_IsResolved: "已解决",
  LB_QA_IsWonderful: "采纳",
  LB_QA_IToAnswer: "我要回答",
  LB_QA_Mine: "我的",
  LB_QA_NewQuestion: "最新问题",
  LB_QA_NoReplyMsg: "暂时没有人回复哦，大神快来帮忙解决吧",
  LB_QA_PleaseEnter: "请输入",
  LB_QA_Praised: "被赞",
  LB_QA_ReplyQuestion: "回复追问",
  LB_QA_Status: "解决状态",
  LB_QA_UnResolved: "未解决",
  LB_QA_YourCreditBalance: "您的积分余额",
  LB_Ranking_FinishCourse: "{0}门课",
  LB_Ranking_FinishPeriod: "{0}学时",
  LB_RecentSearch: "最近搜索",
  LB_ReferenceTimes: "参考次数：{0}次",
  LB_RefreshSuccessful: "刷新成功",
  LB_Rule_Action: "动作",
  LB_Rule_Cycle: "周期",
  LB_Search_Clear: "确定要清除搜索记录吗?",
  LB_SelectEndTime: "选择结束时间",
  LB_SelectStartTime: "选择开始时间",
  LB_SendOut: "发送",
  LB_Set_EmptyPassword: "原始密码不能为空",
  LB_Set_OriginalPassword: "原始密码长度必须为6-20位",
  LB_Settings_Birthday: "生日",
  LB_Settings_Cellphone: "手机",
  LB_Settings_ConfirmPasswordNotNull: "确认密码不能为空",
  LB_Settings_Email: "邮箱",
  LB_Settings_Msg_EmailFormatError: "邮箱格式不正确",
  LB_Settings_Msg_TelephoneFormatError: "座机号码不正确",
  LB_Settings_NewPasswordNotNull: "新密码不能为空",
  LB_Settings_NotTheSamePassword: "两次密码不一致",
  LB_Settings_OriginalPasswordIncorrect: "原始密码不正确",
  LB_Settings_PasswordLengthError: "密码长度必须为6-20位",
  LB_Settings_Sex: "性别",
  LB_Settings_Telephone: "座机",
  LB_Shield: "屏蔽",
  LB_Shielding: "已屏蔽",
  LB_Signup_RegistrationType: "报名类型",
  LB_StartMakeUp: "开始补考",
  LB_Study_ComeOn: "同学，您还有{0}个待学习项需要完成。加油！",
  LB_Subject_DiscussionCircle: "讨论圈",
  LB_SubmitEvalute: "提交评估",
  LB_SubmitStatus: "提交状态",
  LB_Survey_DispatchMethod: "下发方式",
  LB_Survey_Participate: "参与问卷",
  LB_Survey_SubmitSuccessAndJumping: "问卷提交成功，正在返回...",
  LB_Tagged: "标记",
  LB_AnswerSheet: "答题卡",
  LB_Teacher_CreateTime: "创建时间",
  LB_Teacher_HavaPerusal: "已批阅",
  LB_Teacher_InteriorTeacher: "内部讲师",
  LB_Teacher_NoAnyServiceExperience: "暂无任何服务经历",
  LB_Teacher_NoAnyWorkExperience: "暂无任何工作经历",
  LB_Teacher_SubSuccess: "提交成功",
  LB_Teacher_TeacherType: "讲师类型",
  LB_Teacher_WithoutTeacher: "外部讲师",
  LB_TotalData: "共有{0}条数据",
  LB_Track_LastPageAlert: "亲，已经到最后了",
  LB_TrainingDetails: "培训详情",
  LB_TrainingProgram: "培训大纲",
  LB_UnlimitedTimes: "不限次数",
  LB_UploadingAttachments: "上传附件",
  LB_WinningResult: "中奖结果",
  M_Ranking_Integral: "获得积分",
  MN_Course_MHome: "微课中心",
  MN_DocumentCenter_MHone: "知识中心",
  MN_Exercise_MHone: "模拟练习",
  MN_Group_MHone: "讨论组",
  MN_IntegralMall_MHome: "积分商城",
  MN_KC_MHome: "答题竞赛",
  online_exam: "在线考试",
  MN_LearningPaths_MHome: "学习路径图",
  MN_MyCourse_MHome: "我的课程",
  MN_MyWebCast_OldLive: "往期直播",
  MN_MyWebCast_PCStudyCenter: "我的直播",
  MN_News_LeftMenu: "新闻资讯",
  MN_OfflineTraining_MHome: "面授培训",
  MN_QA_MHome: "问答",
  MN_Subject_MHome: "专题培训",
  MN_Survey_MHome: "问卷",
  MN_TrainingEnrollment_MHome: "培训报名",
  MN_TrainingProject_MHome: "培训项目",
  MN_TrainingProject_Detail: "项目详情",
  MN_Exam_Detail: "考试详情",
  Pub_AlreadyReceive: "已领取",
  Pub_AlreadyRegistered: "已报名",
  Pub_Article: "文章",
  Pub_Authorize: "授权",
  Pub_Btn_AlreadySign: "已签到",
  Pub_Btn_Edit: "编辑",
  Pub_Btn_Submit: "提交",
  Pub_Close: "关闭",
  Pub_Completed: "已完成",
  Pub_CT_AssembleForTraining: "集训",
  Pub_CT_SingleDoor: "单门",
  Pub_Data: "知识",
  Pub_Day: "天",
  Pub_Document: "文档",
  Pub_Elective: "选修",
  Pub_Essence: "精华",
  Pub_Exam_NoExam: "暂不可考试",
  Pub_Exam_SubmitExamIsTimeOver: "考试时间已结束，正在提交试卷...",
  Pub_Female: "女",
  Pub_Hour: "小时",
  Pub_Join: "已参加",
  Pub_Lab_Administrators: "管理员",
  Pub_Lab_AnonymousSubmission: "匿名提交",
  Pub_Lab_Collect: "收藏",
  Pub_Lab_Intro: "简介",
  Pub_Lab_NoIntro: "暂无简介",
  Pub_Lab_CreateTime: "创建时间",
  Pub_Lab_DirectoryName: "目录名称",
  Pub_Lab_DocType: "知识类型",
  Pub_Lab_ExamSource: "考试来源",
  Pub_Lab_ExamStatus: "考试状态",
  Pub_Lab_ExamTime: "考试时间",
  Pub_Lab_FinishStatus: "完成状态",
  Pub_Lab_Group: "讨论组",
  Pub_Lab_HomeworkSource: "作业来源",
  Pub_Lab_IsExpired: "是否过期",
  Pub_Lab_MarkingStatus: "批阅状态",
  Pub_Lab_NoData: "暂无数据",
  Pub_Lab_NoRank: "暂无更多排名",
  Pub_Lab_PendingAudit: "待审核",
  Pub_Lab_PleaseEnterSearchKeywords: "请输入搜索关键词",
  Pub_Lab_Post: "岗位",
  Pub_Lab_ProjectStatus: "项目状态",
  Pub_Lab_ProjectTime: "项目时间",
  Pub_Lab_PublishedIn: "发表于",
  Pub_Lab_ReplyMe: "回复我的",
  Pub_Lab_ReplySuccess: "回复成功",
  Pub_Lab_StudyType: "学习类型",
  Pub_Lab_SurveyStatus: "问卷状态",
  Pub_Lab_ThroughCondition: "通过条件",
  Pub_Lab_Top: "置顶",
  Pub_Lab_Topic: "话题",
  Pub_Lab_UploadTime: "上传时间",
  Pub_Lab_UserAudit: "人员审核",
  Pub_Lab_UserName: "姓名",
  Pub_LP: "学习地图",
  Pub_Man: "男",
  Pub_Minutes: "分",
  Pub_Msg_ActivityNoOpen: "当前活动未开启",
  Pub_Msg_DJoinG: "确定要加入该小组？",
  Pub_Msg_DOutG: "确定要退出小组？",
  Pub_Msg_DUAuditF: "确定该用户审核不通过？",
  Pub_Msg_DUAuditS: "确定该用户审核成功？",
  Pub_Msg_NoGetHonor: "您还未获得此勋章",
  Pub_Msg_NotGet: "未获得",
  Pub_Msg_NoRepeatSubmit: "不可重复提交",
  Pub_Msg_SaveFail: "保存失败!",
  Pub_Msg_SaveSuccess: "保存成功!",
  Pub_Msg_SubmitFail: "提交失败!",
  Pub_Msg_SuccessComment: "评论成功	",
  Pub_Msg_TitleNotNull: "标题不能为空",
  Pub_Normal: "正常",
  Pub_Notexpired: "未过期",
  Pub_NotObtainedCertificate: "您还未获得此证书",
  Pub_Notpassed: "未通过",
  Pub_NotRegistered: "未报名",
  Pub_NotSubmitted: "未提交",
  Pub_Open: "公开",
  Pub_Ord_AgreeNum: "被赞数",
  Pub_Ord_Answer: "回答数",
  Pub_Ord_CompleteProgress: "完成进度",
  Pub_Ord_Progress: "完成进度",
  Pub_Ord_Recommend: "推荐",
  Pub_Ord_ReplayTime: "回复时间",
  Pub_Ord_ViewNum: "查看数",
  Pub_Passed: "已通过",
  Pub_PathMap: "路径图",
  Pub_Rebut: "被驳回",
  Pub_RecommendedCourse: "推荐课程",
  Pub_Required: "必修",
  Pub_Submitted: "已提交",
  Pub_Tab_AlreadyRegistered: "已报名",
  Pub_Tab_Assessment: "评估表",
  Pub_Tab_Attended: "参加了",
  Pub_Tab_Checkpoint: "关卡",
  Pub_Tab_CommentNum: "评论",
  Pub_Tab_Credit: "学分",
  Pub_Tab_CT: "面授",
  Pub_Tab_Detail: "详情",
  Pub_Tab_Enrollment: "报名人数",
  Pub_Tab_EveryDay: "每天",
  Pub_Tab_GetIntegralByday: "连续签到 {0} 天 额外奖励 {1} 积分",
  Pub_Tab_GoSign: "去报名",
  Pub_Tab_Knowledge: "知识",
  Pub_Tab_LastStudy: "上次学习",
  Pub_Tab_NotStart: "未开始",
  Pub_Tab_Progress: "进度",
  Pub_Tab_ReplyTo: "回复于",
  Pub_Tab_ReRegistration: "重新报名",
  Pub_Tab_Score: "得分",
  Pub_Tab_Time: "时间",
  Pub_Tab_TrainingTime: "培训时间",
  Pub_TaskOrCT: "项目/面授",
  Pub_Uncommitted: "未参加",
  Pub_Uncompleted: "未完成",
  Pub_Video: "视频",
  Pub_WithoutApproval: "未批阅",
  Sys_Exam: "考试",
  Sys_Homework: "实操作业",
  Sys_WatchLive: "观看 {0} 分钟",
  Sys_WebCast: "直播",
  AI_Practice: "AI对练",
  AI_Practice_tips: "请前往移动端参与AI对练",
  XB_AccordingAcademicScore: "按获得学分数",
  XB_Add_Content: "附加内容",
  XB_AddElectives: "添加选修",
  XB_AddingTaskFailed: "新增任务失败",
  XB_Advance: "向您推荐",
  XB_AreYouFinished: "确定完成吗?",
  XB_AreYouSureCancelTopping: "确定取消置顶吗?",
  XB_AreYouSureDeleteComment: "确定删除此评论吗?",
  XB_AreYouSureDeleteGroup: "确定删除当前讨论组吗?",
  XB_AreYouSureDeleteReply: "确定删除此回复吗？",
  XB_AreYouSureDeleteTopic: "确定删除当前话题吗?",
  XB_AreYouSureEssenceStatus: "确定取消精华状态吗?",
  XB_AreYouSureQuitGroup: "确定退出当前讨论组吗?",
  XB_AreYouSureSetElite: "确定设为精华状态吗?",
  XB_AreYouSureTopStatus: "确定设为置顶状态吗?",
  XB_AuthorizationTime: "授权时间",
  XB_Back_Home: "返回主页",
  XB_Basic_Info: "抄送内容已包括培训的基本信息,此为附加内容",
  XB_BeenExitedGroup: "已退出当前组",
  XB_BeingPublished: "发表中...",
  XB_BindAccount: "绑定账号",
  XB_BindingFailed: "绑定失败",
  XB_BindingSucceeded: "绑定成功",
  XB_Bonus_Points: "打赏的积分不可为空",
  XB_ByNumberCompletedResources: "按完成资源数",
  XB_Cc: "抄送",
  XB_Check_Staff: "签到人员",
  XB_Choose_Content: "请选择抄送内容",
  XB_Choose_Method: "请选择抄送方式",
  XB_ChooseOperation: "选择操作",
  XB_Class_Address: "上课地址",
  XB_CompanyRanking: "公司排行",
  XB_Completed_Course: "恭喜您完成课程",
  XB_Completed_First: "必须先完成上一章节!",
  XB_ConfigurationExam: "配置考试",
  XB_ConfirmPassword: "确认密码",
  XB_CongratulationsGetCert: "恭喜您获取【{0}】证书",
  XB_CongratulationsGetMedal: "恭喜您获取【{0}】勋章",
  XB_Copy_Send_Info: "抄送信息",
  XB_Copy_Send_Method: "抄送方式",
  XB_CorporateIdentity: "企业标识",
  XB_CourseInformation: "课程信息",
  XB_CustomsClearance: "通关",
  XB_DesignatedLearners: "指定学习人员",
  XB_DetailsNumber: "明细数",
  XB_Distinguish: "长按识别二维码",
  XB_Duration: "时长",
  XB_DynamicQRCode: "动态二维码",
  XB_End_Time: "结束时间已过",
  XB_EndTimeCannotEarlierThanStartTime: "结束时间不能小于开始时间",
  XB_Enter_Class_Address: "输入上课地址",
  XB_Enter_Face_Name: "输入面授名称",
  XB_EnterpriseIdNotEmpty: "企业标识不能为空",
  XB_EssenceBeenCanceled: "已取消精华状态",
  XB_Exam_AnswerCannotBeViewed: "不可查看答卷",
  XB_Exam_AreYouSureSaveAnswerInfo: "确定要保存当前答题信息吗？",
  XB_Exam_SaveAnswerInfo: "已为您保存答卷信息",
  XB_ExaminationOpeningConditions: "开考条件",
  XB_ExitFailed: "退出失败",
  XB_Face_To_Face: "面授基本信息",
  XB_Famous_Teachers: "名师推荐",
  XB_FirstCompleteFrontResource: "请先完成前置资源",
  XB_Five_Star_Class: "五星好课",
  XB_FixedQRCode: "固定二维码",
  XB_Get_Integral: "输入可获得积分，默认为0",
  XB_GetFollowingHonors: "通过该学习地图可获得以下荣誉：",
  XB_Give_Reward: "请打赏",
  XB_GiveUpContinueAnswerQuestions: "确定要放弃继续答题吗？",
  XB_Good_Review: "给个好评鼓励下吧",
  XB_Growth_Sharing: "成长分享",
  XB_Heat: "热度",
  XB_Hello: "你好",
  XB_Time: "时",
  XB_HowManyDaysExpire: "还有{0}天到期",
  XB_HowManyPeople: "当前上榜{0}人",
  XB_Insufficient_Points: "积分不足",
  XB_Interaction_Page: "正在返回至互动主页",
  XB_InteractiveConfiguration: "互动配置",
  XB_Invitation: "邀请函",
  XB_Invited: "邀请你来学习",
  XB_Is_Required: "为必填项",
  XB_ItsCourse: "TA的课程",
  XB_Join_Study: "加入学习",
  XB_Join_Study_First: "请先加入学习",
  XB_Join_Successfully: "加入成功",
  XB_Joined: "已加入",
  XB_JoinFailed: "加入失败",
  XB_Learn: "学习了",
  XB_Learn_Together: "共同学习",
  XB_Lecturer: "主讲老师",
  XB_Lecturer_Details: "讲师详情",
  XB_LoginInProgress: "登录中...",
  XB_Mail: "邮件",
  XB_Mall_BeenRedeemed: "已兑完",
  XB_Mall_ExchangeFailed: "兑换失败！",
  XB_Manuscripts: "文稿",
  XB_MobileCannotDownload: "移动端无法下载，请前往PC端",
  XB_MobileCTNotEdit: "在Mobile端，集训目前不支持编辑",
  XB_MobileOnlyForDisplay: "PC端配置,Mobile仅作展示",
  XB_Modify_Job: "修改作业",
  XB_Month: "本月",
  XB_MyMotto: "我的座右铭",
  XB_Nail: "钉钉",
  XB_NewFailed: "新增失败",
  XB_NewPassword: "新密码",
  XB_NoExamYet: "暂无考试",
  XB_NoHistoricalRecord: "暂无历史记录",
  XB_Not_Converted:
    "当前章节系统正在转换，请耐心等待，自动转换完成后方可学习。",
  XB_Not_Drag: "不能拖动到未观看的进度",
  XB_NotCollected: "您还未领取",
  XB_NotFilledIn: "未填写",
  XB_NoUpdateLog: "暂无更新日志",
  XB_NumberLevels: "关卡数",
  XB_NumberStages: "阶段数",
  XB_Obtain_certificates: "您已获得以下证书",
  XB_Obtain_medals: "您已获得以下勋章",
  XB_Open_To_Use: "开启方可使用",
  XB_OptionalCourses: "可选课程",
  XB_OriginalPassword: "原始密码",
  XB_OtherConfigurations: "其它配置",
  XB_Parameter_Error: "参数错误",
  XB_PassExamGetFollowCert: "通过考试可获得以下证书:",
  XB_PassExamGetFollowMedals: "通过考试可获得以下勋章:",
  XB_Pattern: "花样",
  XB_Person_Learning: "{0}人在学",
  XB_PleaseChooseEndTime: "请选择结束时间",
  XB_PleaseChooseLecturer: "请选择主讲老师",
  XB_PleaseChooseStartTime: "请选择开始时间",
  XB_PleaseEnterClassAddress: "请输入上课地址",
  XB_PleaseEnterLessonHour: "请输入正确的课时",
  XB_Popular_Recommendation: "爆款推荐",
  XB_Press_Send: "长按上方图片，保存或发送给朋友",
  XB_PublicationFailed: "发表失败",
  XB_PublishedSuccessfully: "发表成功",
  XB_RankingList: "排行榜",
  XB_ReadIt: "阅读了",
  XB_RegistrationControl: "报名控制",
  XB_RequiresCompletionFrontLevel: "解锁需完成前置关卡",
  XB_Return: "您已成功提交作业,正在返回上一页",
  XB_Returning: "正在返回",
  XB_Reward_Failed: "打赏失败",
  XB_Saving: "保存中...",
  XB_Select_Send_Method: "选择抄送方式",
  XB_SelectCompletionCriteria: "选择完成条件",
  XB_SelectedCourses: "已选课程",
  XB_SelectQRCodeType: "选择二维码类型",
  XB_Send_Not_Empty: "抄送人不能为空",
  XB_Send_Success: "发送成功",
  XB_SendTopic: "发话题",
  XB_SetupSucceeded: "设置成功",
  XB_Share: "分享",
  XB_Share_Course: "分享给你",
  XB_SignUpSuccessfully: "报名成功",
  XB_Singing_Off: "签退人员",
  XB_SorryNoMatchingAccount:
    "抱歉，未能在学习平台中找到匹配的账号,请与管理员联系！",
  XB_StageNotOpen: "阶段未开启",
  XB_Star_Score: "轻触星星评分",
  XB_Start_Quick: "快速开始学习",
  XB_StartLessonReminder: "开课提醒",
  XB_Strive: "努力",
  XB_Submit_Success: "提交成功，正在返回...",
  XB_Success_Back: "您已成功提交评估,正在返回上一页",
  XB_SummaryFirst: "请先填写总结内容",
  XB_SupportedFileFormats: "支持的文件格式：",
  XB_Teaching_Hours: "授课课时",
  XB_TemporarilyUnableToLearn: "暂不可学",
  XB_Test_Scores: "考试分数",
  XB_TheVersionNumber: "版本号",
  XB_Think_Good: "觉得这门课程不错",
  XB_Today: "今日",
  XB_ToppingBeenCancelled: "已取消置顶状态",
  XB_UnbindingFailed: "解绑失败",
  XB_UnbindingSucceeded: "解绑成功",
  XB_UploadingDocument: "正在上传文件...",
  XB_UserNameNotEmpty: "用户名不能为空",
  XB_VerifyYourIdentity: "正在验证您的身份，请稍等...",
  XB_WatchedIt: "观看了",
  XB_WeChart: "微信",
  XB_Week: "本周",
  XB_YouNotBound: "您还没有绑定学习平台账号，赶紧绑定，开始微信学习吧！",
  LB_Account_BindingAccount: "绑定帐号",
  XB_Course_CompletionConditions: "课程完成条件",
  XB_Finish: "完成",
  XB_Test: "测试",
  XB_Text: "文档",
  XB_DocumentText: "文本",
  XB_Course_ChapterSituation: "课程章节情况",
  XB_Studying: "学习中",
  XB_FinishedLearning: "已学完",
  XB_DidNotLearn: "未学习",
  XB_StartDate: "开始时间",
  XB_EndDate: "截止时间",
  XB_ViewRules: "查看规则",
  XB_NoMoreExamRecordsSo: "暂无更多考试记录，赶快参加考试吧",
  XB_ExamDate: "考试日期",
  XB_Min: "分钟",
  XB_JustMomentPendingApproval: "请稍等，试卷等待批阅中。",
  XB_CongratulationsPassedExam: "恭喜您，通过了本次考试！",
  XB_UnfortunatelyNoPassExam: "很遗憾，您没有通过考试。",
  XB_ExaminationHistory: "考试历史记录",
  XB_Mark: "分数",
  XB_NoMoreExamRecords: "暂无更多考试记录",
  XB_ThisExamRules: "本场考试规则",
  XB_ExamNeedingAttention: "考试人员应注意如下事项",
  XB_PassExamToGet: "通过考试可获得",
  XB_Waiting: "等待中",
  XB_CurrentIntegralAre: "您当前积分为",
  XB_AdminSideslipEdit: "管理员侧滑可编辑组员",
  XB_MyRanking: "我的排行",
  XB_DefeatOpponent: "击败对手",
  XB_SpecifyLearningContent: "指定学习内容",
  XB_CertificatesCanObtainedTask: "完成任务可获得的证书",
  XB_MedalCanObtainedTask: "完成任务可获得的勋章",
  XB_UnderWay: "进行中",
  XB_OpeningHours: "开放时间",
  XB_ExerciseDuration: "练习时长",
  XB_RemainingDuration: "剩余时长",
  XB_CorrectNumber: "正确数",
  XB_TotalNumberQuestions: "总题数",
  XB_StudentMedal: "学员勋章",
  XB_CertificateHonor: "荣誉证书",
  XB_FacialIdentification: "人脸识别",
  XB_HasSampled: "已采样",
  XB_Resampling: "重新采样",
  XB_Unthread: "不通过",
  XB_WantTakeSamples: "我要采样",
  XB_SignInWithPrizes: "签到有奖",
  XB_SignInTodayGet: "今日签到获得",
  XB_QuickRegistration: "快速注册",
  XB_AnalysisQuestions: "试题解析",
  XB_GradeDifficulty: "难度等级",
  XB_EndExercise: "结束练习",
  XB_Collected: "已收藏",
  XB_CorrectRateTime: "本次正确率",
  XB_MyWrongQuestion: "我的错题",
  XB_NumberExercises: "练习数",
  XB_WrongExercises: "错题练习",
  XB_BackExercises: "返回练习",
  XB_ExerciseTimeExpired: "当前练习参与时长已到，无法再次参与",
  XB_CurrentErrorEmpty: "当前错题为空",
  XB_SorryNoExercises: "对不起，您没有可参与的练习",
  XB_SorryNoExam: "对不起，您没有可参与的考试",
  XB_InitExercisesFailed: "初始化练习记录失败",
  XB_ContinueLastAnswer: "是否继续上次的答题？",
  XB_MockExamFailed: "您当前模拟考试未通过，得分",
  XB_MockExamPassed: "您当前模拟考试已通过，得分",
  XB_MyQuestionBank: "我的题库",
  XB_ExamRecord: "考试记录",
  XB_MockExam: "模拟考试",
  XB_SwitchQuestionBank: "切换题库",
  XB_SequentialPractice: "顺序练习",
  XB_SequenceExerciseTopic: "按照指定顺序练习题目",
  XB_RandomPractice: "随机练习",
  XB_RandomExerciseTopic: "随机规则练习题目",
  XB_DifficultExercises: "难题练习",
  XB_CarefullyScreenDifficultProblems: "精心筛选该题库中所有选难题",
  XB_MyCollection: "我的收藏",
  XB_CollectExercises: "在模拟练习收藏重点题目",
  XB_WrongQuestionSet: "错题集",
  XB_SummarizeExerciseMistakes: "总结练习过程中所有错题",
  XB_SwitchExercise: "切换习题",
  XB_SelectValidExercise: "请选择一个有效的练习",
  XB_NoExercises: "暂无练习题",
  XB_NoLeaderboard: "暂无排行榜",
  XB_NoExamRecord: "暂无考试纪录",
  XB_NoExamFrequency: "您的考试次数已用尽",
  XB_CurrentNotRanking: "当前排名没有上榜，继续加油哦~",
  XB_NoScoreRanking: "您还没有考试,没有分数排名哟~",
  XB_CongratulationsRight: "恭喜您,答对了",
  XB_GotOnlyPartRight: "您只答对了一部分",
  XB_UnfortunatelyAnswerWrong: "很遗憾,您答错了",
  XB_Cockpit: "驾驶舱",
  XB_Files: "档案",
  XB_ViewPlayback: "查看回放",
  XB_ServiceAgreement: "服务协议",
  XB_PrivacyPolicy: "隐私政策",
  XB_Interactions: "{0}个互动",
  XB_LogOut: "退出登录",
  login: {
    账号: '账号',
    account_p: '请输入您的手机号/邮箱/账号',
    account_p2: '请输入账号！',
    password: '密码',
    password_p: '请输入密码',
    captcha: '验证码',
    请输入验证码: '请输入验证码',
    register: '注册账号',
    forgot: '忘记密码',
    scan_code_login: '扫码登录',
    account_login: '账号登录',
    use_palform_scan: '请使用{0}扫描二维码登录',
    wx: '微信',
    ding: '钉钉',
    your_q_r_code_is_invalid: '您的二维码已失效',
    please_click_the_refresh_button_below: '请点击下方刷新按钮',
    refresh: '刷新',
    failed_to_create_q_r_code: '创建二维码失败',
    login_failed: '登录失败',
    //
    mobile_or_email: '手机号或邮箱',
    mobile_or_email_p: '请输入手机号或邮箱',
    mobile_or_email_err: '手机号或邮箱不正确',
    code: '验证码',
    code_p: '请输入验证码',
    new_password: '新密码',
    new_password_p: '请输入新密码',
    confirm_password: '确认密码',
    confirm_password_p: '请输入确认密码',
    not_match: '两次密码不一致',
    to_login: '去登录',
    successfully_retrieved_the_password: '成功找回密码！',
    success_tip: '您的密码已经重置，快去登录平台试试吧。',
    get_code: '获取验证码',
    failed_get_code: '获取验证码失败',
    //
    account_registration: '账号注册',
    enterprise_registration: '单位注册',
    enterprise: '单位',
    enterprise_p: '请选择单位',
    enterprise_tip: '如果单位列表中找不到所在单位，请联系单位负责人 | 先在平台进行',
    account_regist: '请输入至少三个字符',
    name: '姓名',
    name_p: '请输入姓名',
    address: '地址',
    area: '城市',
    area_p: '请选择城市',
    mobile: '手机号',
    请输入手机号: '请输入手机号',
    mobile_err: '手机号不正确',
    email: '邮箱',
    email_p: '请输入邮箱',
    请输入: '请输入',
    请选择: '请选择',
    字以內: "（{total}字以內）",
    可輸入: "可輸入{total}位小數",
    請輸入整數: "請輸入整數",
    register_btn: '注册',
    register_success_title: '恭喜您，注册成功！',
    register_success_tip: '您的账号已经注册成功，快去登录平台试试吧。',
    enterprise_name: '单位名称',
    enterprise_name_p: '请输入单位名称',
    agree: '同意',
    submit: '同意并注册',
    termsOfService: '《Traingo服务条款》',
    legalNotice: '《法律声明和隐私政策》',
    readAndTick: '请阅读并勾选《Traingo服务条款》《法律声明和隐私政策》',
    //
    emial_format_err: '邮箱格式不正确',
    tel_format_err: '座机格式不正确',
    password_len: '密码不能少于8位',
    password_strong: '密码不能少于8位且需要包含大写字母、小写字母、数字和符号中的任意三项',
    please_enter_chinese: '请输入中文',
    please_enter_the_value: '请输入数值',
    //
    personal_information: '个人信息',
    birthday: '出生日期',
    birthday_p: '请选择出生日期',
    landline: '座机',
    landline_p: '请输入座机',
    save_submit: '保存并提交',
    old_password: '原密码',
    ok_to_modify: '确定修改',
    avatar: '头像',
    点击复制:'点击复制',
    upload_avatar: '上传头像',
    logout_tip: '确定要退出登录吗？',
    账号密码登录:'账号密码登录',
    手机号登录:"手机号登录"
  },
  next: '下一步',
  pre: '上一步',
  and: '与',
  mine: {
    month: '月',
    lv: '等级值',
    chatGPT: '飞训聊天机器人',
    live_course: '直播课程',
    my_lecture: '我的授课',
    my_certificate: '我的证书',
    exam_review: '试卷批阅',
    review_completed: '批阅完成',
    my_order: '我的订单',
    activation_key_management: '激活码管理',
    unit_management: '单位管理',
    my_credits: '我的学分',
    my_school_hours: '我的学时',
    my_integral: '我的积分',
    my_likes: '我的点赞',
    current_version: '当前版本',
    already_the_latest_version: '已经是最新版本！',
    clear_the_cache: '确定要清除缓存吗？',
    clear_immediately: '立即清除',
    clear_success: '清除成功',
    change_password_tip: '第一次登录账号 | 登录密码过期',
    change_password_tip_title: '为提高账号安全，{0}必须修改原始密码。',
    camera_permissions: '请前往设置开启相机权限',
    successfully_modified: '修改成功！',
    learning_center: '学习中心',
    my_face: '我的面授',
    my_exam: '我的考试',
    my_activity: '我的活动',
    learning_path: '学习轨迹',
    interaction_center: '互动中心',
    homework_approval: '作业批阅',
    help_manage: '帮带管理',
    self_help: '员工自助',
    lecturer_apply: '讲师申请',
    demand_report: '需求上报',
    educational: '教务管理',
    complete_online_course: '完成在线课程数',
    month_learn_hours: '在线课程+考试用时+培训中的知识',
    month_study_time: '本月学习时长',
    month_study_time_rank: '本月学习时长排行',
    no_task: '暂无任务派发，去学习其他任务吧～',
    set_off: '出发',
    sure_delete_comment: '确定要删除该条评论吗',
    sure_delete_reply: '确定要删除该条回复吗',
    list_date: '排行日期',
    list_together: '榜单合计',
    people: '人',
    company_rank: '公司排行榜',
    department_rank: '部门排行榜',
    see_more_list: '查看更多排行榜',
    message_center: '消息中心',
    total_of: '共有 | 条',
    delete_msg_tip: '确定要删除当前选中的消息吗？',
    please_choose_to_delete_message_content: '请选择要删除的消息内容',
    cumulative_signin: '本月累计签到{0}天',
    sign_in: '签到中',
    current_level: '当前等级',
    tier_points: '等级积分值',
    level_comparison_table: '等级对照表',
    level_top: '您的等级已经登顶，难道是传说中的大神？接受众人膜拜吧',
    get_another_nth_points_to_upgrade_to: '再获取{0}点积分值升级到Lv{1}',
    score_rule_tip: '进行以下动作会获得积分奖励，在一个动作周期内你最多得到的奖励次数有限制。',
    learning_archive: '学习档案',
  },
  Version: '版本',
  daily_exam: '每日一练',
  cm_subject: '课程专题',
  cm_package: '培训包',
  training_package_details: '培训包详情',
  training_package_purchase: '培训包购买',
  cm_newstaff: '新人培训',
  newstaff_details: '新人培训详情',
  learning_details: '学习详情',
  news_list: '新闻列表',
  news_details: '新闻详情',
  face_to_face_details: '面授详情',
  questionnaire_details: '问卷详情',
  questionnaire_survey: '问卷调查',
  task_supervision: '任务督导',
  cm_activity: '活动',
  demand_collection: '需求征集',
  cm_project: '培训实施',
  face_lecturer: '学员面授',
  lecturer_face: '讲师面授',
  attendance_face: '考勤面授',
  exam_approval: '考试批阅',
  cm_bbs: '学习社区',
  circle_members: '圈子成员',
  make_up_exam: '补考',
  chat: {
    mode: '文本模式 | 画图模式',
    input_p: '您可以直接在这里输入您的问题哦～',
    load_text: '正在获取消息 | 消息获取成功',
    answer_tip: '您好，很高兴为您服务！遇到什么问题您可以直接在这里输入哦~',
    send_tip: '请输入有效的内容 | 响应中',
  },
  face_state: '面授状态',
  from_start: '距开始',
  from_end: '距结束',
  on_line: '线上',
  offline: '线下',
  issued_online: '线上颁发',
  Valid_until: '有效期至',
  permanent: '永久',
  Review_times: '批阅次数',
  source_task: '来源任务',
  CM_Personal: '个人',
  CM_Unit: '单位',
  CM_OrderId: '订单号',
  XB_AreYouSureDeleteOrder: "确定删除订单吗?",
  validity_period: '有效期',
  currency_symbol: '¥',
  order: {
    month: '个月',
    order_type: '订单类型',
    order_state: '订单状态',
    order_stats_option: '已支付 | 未支付',
    order_id: '订单编号',
    wechat_id: '微信交易号',
    pay_time: '支付时间',
    total: '总金额',
    View_activation_code: '查看激活码',
    Activation_code_list: '激活码列表',
    activation_code: '激活码',
    user: '使用人',
    usage_time: '使用时间',
    price: '单价',
    activation_option: '已激活/未激活',
    use_state_option: '已使用 | 未使用',
    purchase_time: '购买时间',
    purchase_quantity: '购买数量',
    purchasing_price: '购买金额',
    please_enter_package_name: '请输入培训包名称',
    table: {
      serial: '序号',
      package_name: '培训包名称',
      orderId: '订单号',
      buy_account_num: '购买账号数',
      time: '时间',
      remaining_code: '剩余激活码',
      operate: '操作',
      提交时间: '提交时间',
      activation_code: '激活码',
      name: '姓名',
      account: '账号',
      tel: '手机号',
      use_time: '使用时间',
    },
  },
  CM_Year: '年',
  CM_quantity: '数量',
  enterprise: {
    personnel_infor: '人员信息 | 人员信息列表',
    personnel_learning: '人员学习情况 | 人员学习情况列表',
    table: {
      avatar: '头像',
      account: '账号 | 请输入账号',
      real_name: '姓名 | 请输入姓名',
      unit: '单位',
      state: '状态 | 请选择状态',
      package: '培训包 | 请输入培训包名称',
      study_time: '学习时间',
      whether_to_pass: '是否通过',
      learning_progress: '学习进度',
      study_completed: '学习完成 | 请选择完成状态',
      create_time: '创建时间',
    },
    info_state: '正常 | 停用',
    pass_state: '已通过 | 未通过',
    learn_state: '完成 | 进行中 | 逾期未完成',
    complete_time: '完成时间',
    recent_study: '最近学习',
    have_completed_all: '恭喜您任务已经全部学完！',
    have_not_started_learn: "您还没有开始学习！",
    tip: '您已完成 | 个任务，还剩 | 个学习任务！',
  },
  credits: {
    total_credits: '总学分',
    year_credits: '本年获得学分',
    month_credits: '本月获得学分',
    adjust_credits: '调整学分',
    remark: '备注',
    Remark_text: '完成【{0}】的奖励学分',
    online_courses: '在线课程',
    training_task: '培训任务',
    year: '年度',
    tips: '用于记录您完成学习获得的奖励学分',
    all_record: '全部学分记录',
  },
  mytime: {
    total_hours: '总学时',
    year_hours: '本年获得学时',
    month_hours: '本月获得学时',
    Remark_text: '完成【{0}】的奖励学时',
    tips: '用于记录您完成学习获得的奖励学时',
    all_record: '全部学时记录',
    table: {
      name_of_resource: '资源名称',
      resource_type: '资源类型',
      time: '时间',
      descr: '备注说明',
    },
  },
  integral: {
    level_integral: '等级积分',
    available_integral: '可用积分',
    year_integral: '本年获得积分',
    month_integral: '本月获得积分',
    get_time: '获得时间',
    remark: '备注说明',
    answer_practice: '答题练习',
    table: {
      action: '动作名称',
      cycle: '动作周期',
      integral: '积分',
      daily_limit: '每日积分上限',
      cycle_state: '一次性 | 不限制',
      descr: '描述说明',
      bonus_points: '奖励积分',
    },
    tips: '用于记录您登录、分享、评论、资源学习等产生平台活跃度的积分奖励',
    all_record: '全部积分记录',
    cycle_tip: '一次性：该动作只需要完成1次，奖励1次。不限制：该动作可以完成多次，奖励多次。',
  },
  like: {
    news_like: '新闻点赞',
    knowledge_like: '知识点赞',
    like_time: '点赞时间',
    news_category: '新闻分类',
    knowledge_category: '知识分类',
  },
  select_date: '选择日期',
  study: {
    title: '我的学习任务',
    to_be_completed: '待完成',
    month: '月',
    learn_in: '{0}{1}内学完',
    title_2: '我的学习',
    title_tip: '这里有你的学习内容和待办事项',
    learning_tasks: '学习任务',
    no_task: '暂无任务派发，去自主学习吧',
    filter_type: '筛选类型',
    overdue_learn: '逾期可学',
    days_remaining: '剩余{0}天',
  },
  home: {
    search_p: '请输入关键内容',
    more_features: '更多功能',
    referenced: '人已参考',
    go: '进入',
    face: '面授',
    person_study: '人学习',
    waiting: '虚伪以待',
    rank_format: {
      h: `时`,
      m: `分`,
      s: `秒`,
    },
    learned_last_time: '上次学到',
    continue_studying: '继续学习',
    init_data: '初始化数据中，请稍等。',
    cannot_study: "问卷暂不能学习",
    resources_tip: '请先完成前面的资源',
    not_yet_started: '活动暂未开始',
    not_yet_expired: '活动已过期',
    recommend: '推荐',
    hot_list: '热榜',
    top: '置顶',
    infor: '资讯',
    infor_tip: '天下大事、行业资讯尽收眼底',
    konwledge_tip: '企业知识宝库 让你了解业务快人一步',
    no_hot_content: '暂无热榜内容',
  },
  no_lecturer: '暂无讲师',
  search: {
    no_search_record: '暂无搜索记录',
    enter_keywrod: '请输入关键词！',
    history_search: '历史搜索',
    delete_all: '全部删除',
    hot: '热',
    total: '共找到 | 个结果',
    noData: '抱歉，没有搜索到相关内容～',
  },
  resource: {
    resource_center: '资源中心',
    resource_center_tip: '课程精选·讲师推荐',
    featured_courses: '课程精选',
    all_courses: '全部课程',
    view_more_course: '查看更多课程内容',
    no_course: '暂无课程内容',
    knowledge_selection: '知识精选',
    all_knowledge: '全部知识',
    no_knowledge: '暂无知识内容',
    industry_big_coffee: '行业大咖',
    no_industry: '暂无行业大咖',
  },
  category: '分类',
  course: {
    learning_times: '学习次数',
    total_progress: '总进度',
    switch_video_play_mode: '切换视频播放模式',
    audio_play: '音频播放',
    video_play: '视频播放',
    now_playing: '（正在播放）',
    speed: '倍速',
    no_course_permission: '您没有该课程的学习权限',
    confirm_learning: '请确认您是否还在学习？',
    please_enter_course: '请输入课程名称',
    format: '格式',
    to_learn: '再学',
    lessons_learned: '课程学完',
    you_have_completed_this_section_of_study: '恭喜，您已完成本章节的学习',
    classes: '{0}节课程',
    people_score: '{0}人打分',
    course_intro: '课程介绍',
    course_catalog: '课程目录',
    course_grade: '课程评分',
    course_comment: '课程评论',
    section: '第{0}节',
    course_not_exist: '抱歉，您访问的课程不存在。',
    this_chapter_is_currently_playing: '当前正在播放此章节',
    the_current_chapter_is_not_converted: '当前章节未转换完成',
    thank_you_for_your_rating: '感谢您的评分！',
    study_stop_continue: '当前学习已暂停，是否继续学习',
    face_tip: '请将您的 | 脸部 | 放入识别框内识别',
    face_click_tip: '已识别到人脸，请点击确定',
    evaluate: '评估',
    homework: '作业',
    go: '去评分 | 写作业 | 去考试',
    need_tip: '课程章节未学完，暂不可考试',
    looking_forward_to_your_positive_comments: '期待您的好评',
    your_encouragement_can_make_us_do_better: '您的鼓励能让我们做得更好',
    online_course: '在线课程',
  },
  project: {
    project_classify: '项目分类',
    implement_classify: '实施分类',
    left_over: '剩余{0}',
    assign_personnel: '指派人员',
    overdue_not_completed: '逾期未完成',
    time_left: '剩余时间',
    project_progress: '项目进度',
    total_of: '共{0}场',
    supervisor: '监督人',
    progress_needs: '项目进度需要达到{0}%',
    exams_required_pass: '考试需要及格{0}场',
    cannot_study: '项目未开始不能学习',
    project_overdue: '项目已逾期',
    passing_the_exam: '考试成绩及格后才可过关',
    stages: '阶段{0}',
    details: '详情介绍',
    catalog: '培训目录',
    comments: '学习评论',
    require: '要求',
    not_exist: '抱歉，您访问的培训项目不存在。',
    business_account: '业务账号',
    other_system_business_accounts: '其它系统业务账号',
    default_Pwd: '默认密码',
    other_system_address: '其它系统访问地址',
    empty_tip: '当前账号暂无业务账号，请联系平台管理员',
    noLearning_tip: '学习通过后才可获得业务账号及密码',
  },
  not_unlocked: '未解锁',
  map: {
    month: '个月',
    go_pc: '请前往PC端学习！',
    click_unlock: '请在地图上点击关卡进行解锁',
    learning_cycle: '学习周期',
    learn_in_sequence: '按顺序学习',
    learn_order: '已开启 | 未开启',
    clearance_conditions: '通关条件',
    learning_progress_required: '学习进度要求{0}%',
    pass_exam_num: '考试及格要求{0}场',
    mentor: '帮带导师',
    Learning_maps_overview: '学习地图概览',
    number_exams_passed: '考试通过场数',
    resource_details: '资源明细数',
    no_exist: '抱歉，您访问的学习地图不存在。',
    current_level: '当前关卡',
    level_progress: '关卡进度',
    overall_progress: '整体进度',
    nth_people_study: '等{0}人也在学',
    learning_map_desc: '学习地图说明',
    my_rank_tip: '共 | 名学员一同学习，我的排名是 | ，打败了',
    my_rank_tip_2: '的学友~',
    level_state: '关卡状态',
    Learning_period: '学习期限',
    Learning_progress_tip: '所有资源学习进度之和/资源总个数',
    pass_condition: '过关条件',
    pass_nth_exam: '{0}场考试通过',
    level_desc: '关卡说明',
    help_and_plan: '帮带计划',
    help_and_evaluation: '帮带评价',
    help_to_summarize: '帮带总结',
    table: {
      serial: '序号',
      type: '类型',
      name: '名称',
      status: '学习状态',
    },
    close_modal_tip: '确定关闭当前学习窗口吗？',
    evaluation_mentor: '评价导师',
    i_already_know__confirm_done: '我已知晓，确认完成',
    tutor_no_plan: '导师暂未制定计划',
    submit_summary: '提交总结',
    submit_summary_p: '提交长内容时，建议先用其他软件编辑好，再粘贴到这里',
    continue_to_submit: '继续提交',
    please_fill_out_summary_content: '请填写总结内容',
    "学习地图开启了关卡间按顺序学习，完成上一关卡后才能解锁当前关卡": "学习地图开启了关卡间按顺序学习，完成上一关卡后才能解锁当前关卡",
    "学习地图开启了关卡间按顺序学习，完成上一关卡且达到上一关的学习期限后才能解锁当前关卡": "学习地图开启了关卡间按顺序学习，完成上一关卡且达到上一关的学习期限后才能解锁当前关卡",
    "当前尚未达到学习地图开始学习时间": "当前尚未达到学习地图开始学习时间",
  },
  newstaff: {
    training_state: '培训状态',
    progress: '培训进度',
    congratulations: '恭喜您完成任务！',
    progress_needs: '培训进度需要达到{0}%',
    training_overdue: '培训已逾期',
    no_exist: '抱歉，您访问的新人培训不存在。',
  },
  homework: {
    work_requirements: '作业要求',
    step: '第{0}步',
    optional: '（选填）请填写作业步骤内容，提交长内容时，建议先使用客户端软件编辑好，再粘贴到这里。',
    add_steps: '添加步骤',
    attachment: '{0}个附件',
    review_time: '批阅时间',
    reviewer: '批阅人',
    comments: '评语',
    save_current_step: '请先保存当前编辑的步骤',
    keep_one_step: '至少保留一个步骤',
    upload_attachment_count: '最多可上传{0}个附件',
    save_tip: '请填写内容或上传附件',
    approval: '批阅',
    table: {
      name: '作业名称',
      score: '作业来源',
      wait: '未批阅/总批阅',
      state: '批阅状态',
      operate: '操作',
      提交时间: '提交时间',
    },
    homework_content: '作业内容',
    score: '分数',
    please_fill_in_the_score: '请填写分数',
    full_score: '满分',
    all_approvals_are_complete: '全部批阅完成！',
    successful_approval: '批阅成功',
    sure_delete_step: '确定要删除该步骤吗？',
    valuation: '作业评估',
    StudentList: '学员列表',
    NotEvaluated: '未评估',
    ViewEvaluation: '查看评估',
    AverageScore: '平均分',
    Department: '所属部门',
    NotStarted: '作业未开始',
    HasEnd: "作业已结束",
  },
  cm_upload: '上传',
  upload: {
    uploading: '上传中',
    success: '上传成功',
    max_size_tip: '文件大小不能超过{0}',
    max_count_tip: '最多上传{0}个文件',
    failed: '上传失败',
    accept: '支持图片、文档、音频、视频，最多可上传{0}个附件，单个文件不超过{1}',
    upload_img: '上传图片',
    upload_video: '上传视频',
    file_siez_cannot_be: '文件大小不能为0',
    please_upload_a_picture_or_video: '请上传图片或视频',
    upload_files: '上传文件',
    please_upload_the_file: '请上传文件',
    supported_file_types: '支持文件类型',
    drop_tip: '点击或直接将文件拖拽到此处上传。',
    upload_error: '上传错误',
    the_file_format_is_not_supported: 'The file format is not supported',
  },
  quesition: {
    is_submit_tip: '该活动您已参与!',
    my_result: '我的结果',
    view_statistics: '查看统计',
    please_enter: '请输入',
    view_option_desc: '查看选项说明',
    write_evaluate: '写评价',
    please_enter_evaluate: '请输入评价',
    emial_error: '邮箱格式不正确',
    mobile_error: '手机号不正确',
    please_select: '请选择',
    please_rate: '请打分',
    select_at_least_options: '最少选择{0}个选项',
    select_up_to_options: '最多选择{0}个选项',
    cannot_be_less: '不能小于{0}',
    enter_at_least: '最少输入{0}个字符',
    cannot_be_greater: '不能大于{0}',
    enter_up_to: '最多输入{0}个字符',
    content_is_incomplete: '内容填写不完整！',
    quesition_option_required: '第{0}题至少选择一个标签!',
    activity_has_expried: '活动已过期，您未参与！',
    please_select_answer: '请选择答案',
    please_enter_content: '请输入内容',
    nth_people_have_answered: '已有{0}人回答',
  },
  go_back: '返回',
  second_automatic_jump: '秒自动跳转',
  practice: {
    answer_practice: '答题练习',
    answer_record: '答题记录 | 答题记录',
    mode: {
      daily: '每日答题',
      daily_tip: '太阳每天都是新的',
      interest: '兴趣答题',
      interest_tip: '兴趣是最好的老师',
      wrong: '错题集',
      wrong_tip: '在错题中吸取经验教训',
      simulation: '模拟练习',
      simulation_tip: '掌握一定的应试技巧积累考试经验',
      self: '自主练习',
      self_tip: '找出复习漏洞，避免一错再错',
    },
    vibration: '振动',
    vibration_tip: '答题过程中振动控制',
    iphone_tip: '苹果手机若未打开响铃/静音模式振动，则无法控制答题模式振动效果。',
    sound_effects: '音效',
    sound_effects_tip: '答题过程中答对答错有音效效果',
    integral: '积分值',
    question: '题',
    limit_reached: '积分领取已达上限',
    error_times: '出错次数',
    view_tips: '查看提示',
    number_of_times: '次',
    correct_quesition: '本次答对题目数',
    wrong_quesition: '错题数',
    use_time: '用时',
    integral_2: '积分',
    one_more_group: '再来一组',
    no_quesition: '暂无练习题目',
    exit_tip: '确定要退出答题？ \n退出后作答历史将不会保存',
    remove_tip: '确定要移除错题？\n移除后将不在错题练习中出现',
    remove_success: '移除成功',
    no_interest: '非答题练习开放时段\n敬请期待',
    select_tags: '选择感兴趣标签',
    can_select_multiple: '可以选择多个',
    go_to_answer: '进去答题',
    wrong_question_set: '错题集',
    view_and_practice: '错题查看与错题练习',
    total_wrong_questions: '总错题',
    view_wrong_question: '查看错题',
    wrong_question_practice: '错题练习',
    frequent_wrong_questions: '高频错题',
    simulation_exercise: '模拟练习',
    standard_Practice_tests: '标准模拟练习测试',
    total_questions: '总题数',
    total_score: '总分',
    pass_line: '及格线',
    score: '分',
    enter_practice: '进入练习',
    no_simulation: '非模拟练习开放时段\n敬请期待',
    quesition_num: '第{0}题',
    your_choice: '您的选择',
    time_end_submit: '练习时间已结束，正在提交试卷...',
    sure_submit_paper: '您确定要提交试卷吗？',
    unanswered_to_submit: '还有{0}道试题未答，\n您确定要提交试卷吗？',
    return_page: '您已成功提交了答卷，将返回上一页',
    self_practice: '自主练习设置',
    quesition_bank: '题库总{0}题',
    answer_time: '答题时长',
    paper_total_score: '试卷总分',
    no_self: '非自主练习开放时段\n敬请期待',
  },
  package: {
    purchase_status: '购买状态',
    personal_price: '个人价格',
    enterprise_price: '单位价格',
    purchased: '已购买',
    not_purchased: '未购买',
    personal_purchase: '个人购买 | 个人购买',
    enterprise_purchase: '单位购买 | 单位购买',
    minimum_account: '{0}账号起',
    yuan: '元',
    good_rating: '好评率',
    buyers: '购买人数',
    free_purchase: '免费购买',
    active: '激活',
    price: '单价{0}元',
    less_account: '账号{0}个起购',
    pay_tip: '支付成功后，您将收到一封激活码邮件，也可通过单位管理查看激活码',
    to_pay: '支付',
    form: {
      activation_code: '激活码',
      activation_code_p: '请输入{0}位激活码',
      captcha: '验证码',
      请输入验证码: '请输入验证码',
      exchange: '确认兑换',
      activation_success: '激活成功',
    },
    purchase_success: '购买成功',
    pay_success: '支付成功',
    min_buy: '单位最低购买',
    people_buy: '{0}人购买',
    good_rate: '好评度',
    after_buy_the_course: '购买课程后',
    is_valid_until: '有效期为',
    code_exchange: '激活码兑换',
    details: '详情介绍',
    recommend: '推荐培训包',
    not_exist: '抱歉，您访问的培训包不存在。',
    activation_code_activation: '激活码激活',
    commodity_order: '商品订单',
    payment_amount: '支付金额',
    WeChat_payment: '微信支付',
    qr_expires_tip: '距离二维码过期还剩 | 秒，过期后请刷新页面重新获取二维码',
    open_phone_wechat_pay: '请打开手机微信，扫一扫完成支付',
    qr_has_expired: '二维码已过期，请刷新页面重新获取二维码！',
    scan_code_pay: '扫码支付',
    table: {
      cover: '封面',
      name: '培训包名',
      quantity: '数量',
      price: '价格',
      validity_period: '有效期',
    },
    training_rating: '培训评分',
    package_has_expired: '培训包已逾期',
  },
  cm_price: '价格',
  news: {
    have_read: '已读 | 已阅读',
    publish_time: '发布时间',
    hot: '热',
    new: '新',
    disclaimer: '免责声明',
    copyright_notice: '版权声明',
    finish_reading: '完成阅读',
    no_lickes_allowed: '不允许点赞！',
    no_comments_allowed: '不允许评论！',
    no_exist: '抱歉，您访问的新闻不存在。',
    news_center: '新闻中心',
  },
  cm_download: '下载',
  cm_downloading: '下载中...',
  exam: {
    participation_status: '参与状态',
    test_name: '主考名称',
    times: '考试次数',
    unlimited: '不限次数',
    overdue_can_learn: '逾期后可学',
    make_up_exam: '补考安排',
    enter_make_ip: '进入补考',
    not_yet_exam_time: '还未到考试时间',
    total_score: '考试总分',
    total_score_tip: '本次考试总分为',
    cut_screen_tip: '不允许切屏。',
    cut_screen_tip_2: '本场考试切屏次数为{0}次。',
    rule_unlimited: '无限次',
    cut_screen_tip_3: '超过切屏次数规则系统会强制交卷。',
    exam_record_view: '考试记录查看',
    exam_record_view_tip: '交卷后可查看答卷。',
    exam_record_view_tip_2: '及格后可查看答卷。',
    exam_record_view_tip_3: '考试结束后可查看答卷。',
    exam_record_view_tip_4: '不允许查看答卷。',
    mandatory_submission_rules: '强制交卷规则',
    mandatory_submission_rules_tip: '进入答题界面开始计时, 倒计时结束未自主交卷则系统强制交卷',
    mandatory_submission_rules_tip_2: '进入答题界面开始计时，未交卷退出答题界面，考试用时会继续计时，用时结束系统会强制交卷',
    study_reward: '学习奖励',
    study_reward_tip: '考试通过可获得证书',
    last_count: '剩余次数',
    cannot_exam: "暂不可考试",
    exam_is_over: '考试已结束',
    exam_result: '考试结果',
    exam_result_query: '考试结果查询',
    format: {
      d: '天',
      h: '时',
      m: '分',
      s: '秒',
      now: '刚刚',
      m_ago: '{0} 分钟前',
      h_ago: '{0} 小时前',
      d_ago: '{0} 天前',
      month_ago: '{0} 个月前',
      y_ago: '{0} 年前',
      hh: '小时',
      mm: '分钟',
    },
    make_up_pass: '补考通过',
    view_exam_sheet: '考试及格才能查看答卷 | 考试结束才能查看答卷',
    clear: '清除',
    exam_signature: '考试签名',
    exam_signature_tip: '本场考试开启了"签名确认"，请在下面签名框内使用鼠标签名（长按鼠标）。',
    please_sign: '请签名',
    signed_successfully: '签名提交成功',
    signature_failed: '签名提交失败',
    right_answer: '正确答案',
    case_title: '本大题共{0}小题，总分{1}分',
    expand_case: '展开全部题目',
    view_pic: '点击查看图片',
    view_video: '点击观看视频',
    view_audio: '点击收听音频',
    pre_quesition: '上一题',
    keyword: '关键词',
    score_keywords: '判分关键词',
    single_choice: '单选题',
    multiple_choice: '多选题',
    save: '暂存',
    no_quesition: '暂无试题',
    doexam_cut_screen_tip: '请注意，本次考试不允许切屏',
    doexam_cut_screen_tip_2: '请注意，考试切屏次数还剩{0}次，切屏时间{1}',
    mandatory_submission: '再切屏将会强制交卷!',
    whether_save_paper: '是否保存答卷',
    submit_paper_wait: '交卷中，请稍等',
    in_approval: '待审批',
    my_answer: '我的答案',
    not_score: '未得分',
    quesition_num: '第{0}题',
    fill_in_blanks: '填空',
    not_answer: '未答题',
    answers: '答题答案',
    score_ratio: '得分比例',
    exam_info: '考试信息',
    exam_name: '考试名称',
    approval_count: '批阅数',
    approval_progress: '批阅进度',
    answer_info: '答卷信息',
    student_name: '学员姓名',
    exam_time: '开考时间',
    exam_score: '考试得分',
    successful_approval: '阅卷成功',
    all_approved: '全部已批阅',
    to_score: '得分',
    score: '分',
    nth_score: '{0}分',
    exam_not_started: '考试未开始',
    note: '注',
    exam_description: '考试说明',
    no_description: '暂无说明',
    submit_time: '交卷时间',
    use_time: '考试用时',
    nth_s: '{0}秒',
    exam_err_tip: '抱歉，您访问的考试{0}',
    daily_err_tip: '抱歉，您访问的每日一练{0}',
    total_duration: '总时长',
    auto_scoring_tip: '若有主观题，系统不支持自动判分',
    submit: '交卷',
    unanswered_to_submit: '还有{0}道试题未答 | 确定要提交试卷吗？',
    exam_time_has_end: '考试时间已结束,正在提交试卷...',
    exam_map_submit: "您已成功提交了答卷,将返回学习地图",
    status_of_this: '本次考试状态',
    exam_status: '本场考试状态',
    passing_score: '及格分数',
    join_nums: '参与次数',
    export_answer: '导出答卷',
    ios_export_tip: '苹果电脑不支持导出，请浏览器访问平台进行导出操作',
    my_scoring: '我的得分',
    modify_score_tip: '确定修改题目的得分吗？',
    modify_score_tip2: '确定修改题目的得分为',
    modify_score_tip3: '分吗？',
    exam_again: '再考一次',
    screen_cut_time: '切屏时间',
    screen_cut_time_tip: '超过切屏时间系统会强制交卷',
    infinitely: '不限制',
    examPassword: '考试密码',
    examPasswordError: '考试密码错误',
    PleaseNoteThatTheExamScreenCutTimeIsNthSeconds: '请注意，考试切屏时间为{0}秒'
  },
  has_been_deleted: '已删除',
  unpublished: '已取消发布',
  not_exist: '不存在',
  no_permission: '没有参与权限',
  signup: {
    unlimited: '无限制',
    people: '{0}人',
    training_introduction: '培训介绍',
    training_arrangement: '培训安排',
    do_need_review: '是否需要审核',
    need: '需要',
    no_need: '不需要',
    training_time: '培训时长',
    sign_time: '报名（启）止时间',
    sign_scope: '报名范围',
    company_wide: '全公司',
    contains_subdepartments: '包含子部门',
    project_introduction: '项目介绍',
    reason_for_ejection: '驳回原因',
    signup_start_end: '距离报名开始还有 | 距离报名结束还有',
    signup_end: '报名已截止',
    start_sign_up: '开始报名',
    cancel_sign_up: '取消报名',
    afresh_sign_up: '重新报名',
    subject_no_start: '专题未开始不能学习',
    subject_has_expired: '专题已过期, 无法继续学习',
    sure_sign_up: '确定报名吗？',
    sure_cancel_sign_up: '确定取消报名吗？',
    cancel_sign_up_success: '取消报名成功',
    cancel_sign_up_fail: '取消报名失败',
    registration_not_started: '报名未开始',
  },
  cm_sign_up: '报名中心',
  sign_up_details: '报名详情',
  lecturer_center: '讲师中心',
  lecturer: {
    level: '讲师等级',
    classify: '讲师分类',
    view_lecturer: '查看讲师',
    basic_info: '基本信息',
    online_courses: '在线课程',
    offline_training: '线下授课',
    honor: '讲师荣誉',
    empty: '无',
    lecturer_profile: '讲师简介',
    expert_title: '专家称号',
    areas_of_expertise: '擅长领域',
    teaching_direction: '授课方向',
    field_of_study: '研究领域',
    other: '其他',
    none: '无',
    work_experience: '工作经历',
    professional_rank: '专业队伍职级',
    model_project_team_rank: '型号项目队伍职级',
    course_duration: '课程时长',
    address: '授课地点',
    not_exist: '抱歉，您访问的讲师不存在。',
  },
  subject: {
    learners: '学习人数',
    people: '人',
    time_range: '时间范围',
    no_study_time_limit: '无学习时间限制',
    detail: '专题详情',
    arrange: '专题安排',
    stages: '{0}个阶段',
    course_total: '共{0}门课程',
    learning_progress_reaches: '学习进度达到{0}%',
    learning_time: '学习时间',
    study_certificate: '学习证书',
    intro: '专题介绍',
    learning_progress: '学习进度',
    chapter_situation: '章节情况',
    start: '开始',
    course_not_exist: '抱歉，您访问的课程专题不存在',
    learned: '已学习{0}%',
    nth_people_have_learned: '{0}人已学习',
    special_introduction: '专题简介',
    recommended_subject: '推荐专题',
    course_no_permission: '抱歉，您没有该课程专题的学习权限，请联系管理员',
    overdue_tip: '专题已过期，无法继续学习',
    not_start_tip: '专题未开始不能学习',
  },
  activity: {
    type: '活动类型',
    investigation: '调查问卷',
    evaluate: '评估表',
    vote: '投票',
    total_activity: '共 | 个活动',
    assign_me_one: '给我指派了一个',
    has_expired: '活动已逾期',
    not_started: '活动暂未开始',
    participate: '参与活动',
  },
  to_be_done: '待完成',
  cm_overdue: '已逾期',
  cm_to: '至',
  shop: {
    my_exchange_record: '我的兑换记录',
    integral_rule: '积分兑换规则',
    exchange_number: '每人可兑换数',
    exchange_up_to: '最多可兑换{0}个',
    sure_exchange: '确定兑换吗？',
    exchange_status: '兑换状态',
    points_required: '所需积分',
    added_time: '上架时间',
    stock: '库存',
    commodity_detail: '商品详情',
    commodity_stock: '商品库存',
    pieces: '件',
    people_exchange_num: '此商品每人仅限兑换{0}件',
    have_exchange_num: '已兑换{0}件',
    already_exchange: '已经兑完',
    the_product_details: '商品详细',
    guess_you_like: '猜你喜欢',
    integral_not_enough: '您的积分不足',
    table: {
      commodity_intro: '商品简介',
      single_integral: '单个积分',
      consumption_integral: '消费积分',
      exchange_quantity: '兑换数量',
      exchange_time: '兑换时间',
    },
  },
  live: {
    to_be_completed_live: '待完成直播',
    history_live: '历史直播',
    live_status: '直播状态',
    learning_status: '学习状态',
    required_study_time: '要求学习时长',
    watch_time: '已看时长',
    live_lecturer: '直播讲师',
    speaker: '主讲',
    speaker2: '主讲人',
    participant: '参与者',
    live_time: '直播时间',
    live_detail: '直播详情',
    live_not_started: '直播未开始',
    live_has_ended: '直播已结束',
    view_replay: '查看回放',
    no_exist: '直播不存在',
    start_client: '启动客户端',
    web_live: '网页开播',
    start_live: '开启直播',
    ios_tip: '苹果电脑不支持开启直播，请用企微直播应用开启直播',
    wxwork_tip: '请用企业微信开启直播',
    is_open_dingding_live: '正在打开钉钉直播...',
    view_live: '查看直播',
    wxwork_tip2: '请用企业微信查看直播',
    wxwork_tip3: '请用企业微信参与直播',
  },
  cm_creator: '创建人',
  train: {
    schedule: '面授培训日程表',
    progress: '面授进度',
    scan_code_attendance: '扫码考勤',
    click_scan_qr: '点我扫描二维码，快速录入考勤',
    signed_out: '已签退',
    attendance: '考勤',
    head: '考勤负责人',
    not_exist: '面授不存在',
    on_camera_permission: '请前往设置开启相机权限',
    lesson_hours: '课时',
    lecturer: '授课讲师',
    lesson_rating: '授课评分',
    class_place: '上课地点',
    attendance_time: '签到签退时间',
    no_sign: '未签到',
    no_sign_out: '未签退',
    course_detail: '课程详情',
    course_comment: '课程讨论',
    activity_attachments: '活动附件',
    test_results: '考核成绩',
    lecture_comments: '讲师评语',
    activity_photos: '活动照片',
    other_attachments: '其它附件',
    view_lecture_appraise: '查看讲师评价',
    evaluation_lecturer: '评价讲师',
    view_course_appraise: '查看课程评价',
    evaluation_course: '评价课程',
    enter_meeting: '进入会议',
    ask_for_leave: '请假',
    cancel_leave: '取消请假',
    approving_for_leave: '请假审批中',
    exam_results: '考试成绩',
    teacher_comment: '老师评语',
    view_full: '查看完整',
    sign_in_time: '签到时间',
    sign_out_time: '签退时间',
    not_rated: '未评分',
    lecturer_rating: '讲师评分',
    view_reviews: '查看评价',
    evaluation: '评价',
    no_exist: '抱歉，您访问的面授不存在。',
    please_use_enterprise_wechat_to_participate_in_meeting: '请用企业微信参与会议',
    is_open_dingding_meeting: '正在打开钉钉会议...',
    offline_grades: '线下成绩',
    attendance_info: '考勤信息',
    sign_in_qr: '签到二维码',
    sign_out_qr: '签退二维码',
    grade_infor: '成绩信息',
    pass_score: '及格分',
    entered: '已录入',
    not_entered: '未录入',
    dynamic_q_r_code: '动态二维码，每{0}秒刷新一次',
    sign_in_status: '签到状态',
    sign_out_status: '签退状态',
    attendance_status: '出勤状态',
    batch_operation: '批量操作',
    import_attendance: '导入考勤',
    be_late: '迟到',
    leave_early: '早退',
    absent: '未出勤',
    attended: '已出勤',
    batch_set_attendance: '当前为所选{0}人批量进行人工考勤设置',
    please_select_sign_in_time: '请选择签到时间',
    please_select_sign_out_time: '请选择签退时间',
    attendance_temp: '请先点击 | 下载考勤模板 | ，编辑完内容后上传模板导入考勤',
    select_file: '选择文件',
    reselect: '重新选择',
    drop_file_attendance: '可直接将文件拖拽到此处上传，支持文件类型：xls .xlsx',
    student: '学员',
    please_select_a_student: '请勾选学员',
    checked_one: '至少勾选一项复选框的内容',
    wrong_file_type: '文件类型错误,请选择xls/xlsx文件',
    please_upload_template_file: '请上传模板文件',
    operation_completed_without_importing_any_data: '操作完成，未导入任何数据！',
    import_nth_data: '操作完成，成功导入{0}条数据',
    attendance_template: '考勤模板.xlsx',
    has_been_evaluated: '已评价',
    not_evaluated: '未评价',
    evaluation_status: '评价状态',
    detail: '评价学员详情',
    grading_time: '评分时间',
    grade_status: '成绩状态',
    view_grade: '查看成绩',
    enter_grades: '录入成绩',
    enter_grades_for: '当前正在为 | 录入成绩',
    grades: '成绩',
    qualified_status: '合格状态',
    pass_status: '及格状态',
    entered_successfully: '录入成功',
    attachment_accept: '支持图片、文档、音频、视频，压缩包，最多上传20个附件，单个文件不超过1G',
  },
  cm_contest: '答题竞赛',
  contest: {
    select_activity: '选择活动',
    ba: '吧',
    not_open: '非答题竞赛开放时段',
    stay_tuned: '敬请期待！',
    activity_desc: '活动介绍',
    record: '答题记录',
    my_gold_coin: '我的金币',
    qualifying: '排位赛',
    qualifying_desc: '闯关答题，强者登顶',
    foursome: '四人赛',
    foursome_desc: '在线对抗，强者胜！',
    vs_desc: '双人对战，孰强孰弱！',
    one_stop: '一站到底',
    one_stop_desc: '答错离场，答对继续',
    leaderboard: '排行榜',
    not_unlocked: '尚未解锁',
    not_open_yet: '暂未开放，敬请期待',
    opening_time: '开放时间段为',
    vs: '双人比赛',
    challenge_Record: '挑战记录',
    gold_coin: '金币',
    ranking_stars: '排位星星',
    have_not_participated: "还没有参与过比赛",
    go_game: "快去比一局吧",
    learning_from_the_old: '温故知新',
    quesition: '第 | 题',
    right: '回答正确',
    wrong: '回答错误',
    rule_desc: '规则说明',
    updated_in_real_time: '数据实时更新',
    no_one_on_list: '还没有人登上榜单',
    go_answer: '快去答题上榜吧',
    not_yet_on_the_list: '尚为上榜',
    no_nth_list: '榜上排名第 | 位',
    challenge_now: '立即挑战',
    ranking_rules: '排名规则',
    leaderboard_ranking_rules_1: '展示活动参与人员所有排行信息。',
    leaderboard_ranking_rules_2: '段位越高、星星越多、胜率越高的用户排名越高',
    leaderboard_ranking_rules_3: '排行榜实时更新',
    leaderboard_ranking_rules_4: '注意：只有排位赛获胜才会加星',
    warm_tip_1: '用户成功匹配对手即进入答题流程。手机故障、网络不佳等异常情况会影响比赛得分、排名和金币，建议检查手机设备并在良好的网络环境下参与答题',
    entry_coins: '入场金币',
    win_reward: '获胜奖励',
    failure_reward: '失败奖励',
    competition_rules: '比赛规则',
    qualifying_competition_rule_1: '每局在线匹配用户1人、匹配成功开始作答',
    qualifying_competition_rule_2: '每题分值{0}分，每题答题时间{1}秒',
    qualifying_competition_rule_3: '每局比赛共{0}题，两人对战，总得分越高的获胜，获胜即可奖励一个星星',
    qualifying_competition_rule_4: '每局比赛失败，会扣掉一颗星 | 每局比赛失败，不会掉星',
    qualifying_competition_rule_5: '每局全部用户比赛结束，方可生成答题记录；中途退出提前结束的用户请等比赛结束后查看答题记录',
    qualifying_ranking_rules_1: '星星数越多的用户在排行榜上排名越高',
    warm_tip_2: '苹果手机左滑无法正常退出答题流程，请点击页面左上角返回按钮离开擂台',
    game_not_open: '比赛未开启',
    enough_gold_coins: '金币不足，无法参与！',
    conins_for_place: '金币局第一名{0}个，第二名{1}个，第三名{2}个, 第四名{3}个',
    challenger: '擂主',
    good_network: '网络良好',
    poor_network: '网络较差',
    start_game: '开始比赛',
    gold_coins_today: '今日金币奖励局',
    foursome_competition_rule_1: '每局在线匹配用户4人、匹配成功开始作答',
    foursome_competition_rule_2: '每题答对+{0}分，答错不减分，优先获得100分即为胜利，获得金币奖励',
    foursome_competition_rule_3: '每局比赛最多10分钟，10分钟内无选手达到100分则全部失败',
    foursome_competition_rule_4: '每局全部用户比赛结束，方可生成答题记录；中途退出提前结束的用户请等比赛结束后查看答题记录',
    invite_friends: '邀请好友',
    compete_with_friends: '与好友切磋',
    random_match: '随机匹配',
    fight_passersby: '和路人对战',
    vs_competition_rule_1: '点击空位邀请一名好友或用户（随机）参与对战，擂主具备开局权限',
    vs_competition_rule_2: '每天金币奖励{0}局，获胜+{1}金币，失败+{2}金币',
    vs_competition_rule_3: '注意：参与双人对战不加星，成绩不计入排行榜',
    wrong_leave_right_continue: '答错离场，答对继续',
    matching: '匹配中...',
    successful_match: '匹配成功',
    wait_other_answer: '等待对方答题',
    highest_scorer: '得分高者',
    first_100: '优先到达100分',
    all_right: '全部答对',
    will: '即为获胜',
    readying: '准备',
    start: '开始',
    quesition_num: '第{0}题',
    consecutive_right: '连续答对x{0}',
    congratulations: '恭喜答对啦',
    wrong_answer: '这题答错咯',
    congratulations_to_you: '恭喜你',
    get_win: '获得{0}的胜利',
    unfortunately: '很遗憾',
    win_next_time: '不要气馁，下次再赢',
    challenge_success: '挑战成功',
    challenge_failed: '挑战失败',
    escaped: '已逃跑',
    leave_page: '{0}秒后或点击任意地方离开此页面',
    victory: '获得胜利',
    keep_on: '继续加医',
    review_answer: '回顾本局答题',
    challenge_again: '再次挑战',
    choose_colleagues: '选择同事',
    recently_played: '最近对战',
    company_colleagues: '公司同事',
    send_invite: '发送邀请',
    challenge_ends: '挑战结束',
    answer_quesitions_time: '本次答对 | 题',
    highest_answer_quesitions: '历史最高答对{0}题',
    gold_coin_limit: '金币已达上限',
    end_the_game: '结束本局',
    ranking_result_tip_1: '优先分数排名',
    ranking_result_tip_2: '如果分数一样，优先答题用时短的获胜',
    ranking_result_tip_3: '分数和用时一样，优先最后一题谁先答的获胜',
    ranking_result_tip_4: '逃跑和最后一题掉线，算对方赢，不论分数高低',
    back_tip_1: '确定要离开擂台吗？',
    back_tip_2: '请问您确定要退出吗，中途退出将视为比赛失败，稍后请到金币明细查看金币情况。',
    continue: '继续',
    quit: '退出',
    insufficient_gold_coins: '金币不足',
    rank_not_unlocked: '段位未解锁',
    please_select_colleagues: '请选择要邀请的同事',
    sure_invite_select: '确定邀请当前选中人员吗？',
    invite_you_participate: '{0}邀请你参加双人赛～',
    the_battle_over: '对战结束，没有更多题目了！',
    init_rule: {
      tip_1: '优先获得100分，即为获胜',
      tip_2: '答对+{0}分，答错不减分',
      tip_3: '在线对抗，能者胜',
      tip_4: '资源加载中，马上开始',
      tip_5: '每题分值{0}分',
      tip_6: '每局比赛共{0}题',
    },
  },
  bbs: {
    no_more: '已经到底了',
    please_enter_title_author: '请输入标题/作者',
    post: '帖子',
    ask: '提问',
    article: '文章',
    my_circle: '我的圈子',
    create_circles: '创建学习圈',
    find_circle: '发现圈子',
    topic: '主题',
    topic_2: '话题',
    member: '成员',
    top_zone: '置顶专区',
    top: '置顶',
    essence: '精华',
    admin: '管理员',
    circle_owner: '圈主',
    latest_content: '按最新',
    recommended_content: '按推荐',
    hot_content: '按热度',
    latest_discussion: '按讨论',
    only_view_owner: '只看圈主',
    anonymous_user: '匿名用户',
    invited: '邀请',
    invited_2: '邀请了',
    been_invited_1: '等{0}位被邀请',
    been_invited_2: '被邀请',
    form: '来自{0}',
    internal_link: '内部链接',
    external_link: '外部链接',
    attachment: '附件{0}',
    video: '视频{0}',
    reward_answer_integral: '悬赏回答{0}积分',
    publish: '发布',
    file_status_1: '未转换 | 待转换',
    file_status_2: '转换中 | 转换失败',
    i_will_answer: '我来回答',
    i_will_comment: '我来评论',
    leave_app_tip: '您即将离开此页面，请注意账号安全',
    zip_not_preview: 'zip文件不支持预览',
    join_corcle_like: '加入圈子才能点赞哦！',
    please_publish: '请先发布',
    join_circle_view_detail: '加入圈子才能查看主题详情哦',
    join_circle_can: '加入圈子才能回答哦 | 加入圈子才能评论哦',
    my: {
      publish: '我发布的',
      draft: '我的草稿',
      follow: '我关注的',
      collect: '我收藏的',
      comment: '评论我的',
      answer: '回答我的',
      like: '点赞我的',
      invited: '邀请我的',
    },
    published: '已发布的',
    unpublished: '未发布的',
    invite_me_follow: '邀请我关注{0}',
    my_answer: '我的回答',
    my_comment: '我的评论',
    liked: '已点赞',
    liked_2: '赞了',
    this: '这个',
    i_manage: '我管理的',
    waiting_review: '等待审核',
    apply_join: '申请加入',
    circle_profile: '圈子简介',
    num_members: '{0}位成员',
    after_join: '加入后，可浏览精彩内容',
    member_manage: '成员管理',
    invite_people: '邀请人员',
    disband_circle: '解散圈子',
    quit_circle: '退出圈子',
    invite_you_join_circle: '邀请你加入学习圈',
    scan_code_enter_circle: '扫码立即进入圈子',
    link_browser: '或浏览器输入链接',
    copy_link: '复制链接',
    save_card: '保存邀请卡',
    disband_circle_tip: '确定解散圈子吗？',
    disbanded_successfully: '解散成功！',
    disbanded_failed: '解散失败！',
    quit_circle_tip: '确定退出圈子吗？',
    quit_successfully: '退出成功！',
    quit_failed: '退出失败！',
    copy_successfully: '复制成功',
    back_circle: '返回圈子',
    please_enter_name_account_depart: '请输入姓名、账号、部门搜索',
    topics: '主题数',
    inviter: '邀请人',
    join_time: '加入时间',
    apply_time: '申请时间',
    process_time: '处理时间',
    invite_time: '邀请时间',
    process_opinion: '处理意见',
    remove: '移除',
    set_admin: '设置管理员',
    cancel_admin: '取消管理员',
    agree: '同意',
    reject: '拒绝',
    has_invited: '已邀请',
    sure_agree: '确定同意吗？',
    sure_reject: '确定拒绝吗？',
    please_enter_review: '请输入审核意见（选填）',
    sure_remove: '确定要移除吗？',
    invite_successful: '邀请成功',
    has_joined: '已加入',
    pending_review: '待审核',
    has_rejected: '已拒绝',
    selected: '已选择',
    select_most_people: '最多选择{0}个人员',
    person_is_select: '已选择此人员',
    please_enter_title_name_content: '请输入标题/作者/内容',
    follow: '关注话题 | 关注',
    unfollow: '取消关注 | 取消关注',
    sure_follow_topic: '确定{0}话题吗？',
    follow_successfully: '{0} 成功',
    follow_failed: '{0} 失败',
    search_you_interested: '搜索你感兴趣的内容...',
    search_history: '搜索历史',
    view_all: '查看全部',
    like_2: '赞',
    quesition: '问题',
    follow_quesition: '关注问题',
    featured_answer: '精选回答',
    comment_time: '评论时间',
    comment_hot: '评论热度',
    answer_time: '回答时间',
    answer_hot: '回答热度',
    post_detail: '帖子详情',
    ask_detail: '问答详情',
    article_detail: '文章详情',
    global_top: '全局置顶',
    set_global_top: '改为全局置顶',
    circle_top: '圈内置顶',
    set_circle_top: '改为圈内置顶',
    cancel_top: '取消置顶',
    refined: '加精',
    cancel_refined: '取消加精',
    close_quesition: '关闭问题',
    quesition_has_close: '该问题已关闭',
    edit: '编辑',
    delete: '删除',
    sure_delete: '确定删除吗？',
    i_want: '我要',
    only_owner_can: '仅圈主可以 {0}',
    only_owner_admin_can: '仅圈主/圈子管理员可以 {0}',
    adopted: '被采纳',
    featured_recommended: '精选推荐',
    replied: '回复了',
    in_total: '等{0}位',
    view_replied: '查看{0}条回复',
    adopt: '采纳',
    recommend: '推荐',
    adpoted_cannot_del: '被采纳的回复不能被删除',
    after_adpot_cannot_cancel: '采纳后不可取消，确定要采纳吗？',
    adopted_successfully: '采纳成功',
    after_recommend_cannot_cancel: '推荐后不可取消，确定要推荐吗？',
    recommended_successfully: '推荐成功',
    say_you_want: '说点你想说的',
    remind_who_watch: '提醒谁看',
    remind_who_watch_tip: "{'@'}的人会收到消息提醒",
    anonymous_reply: '匿名回复',
    anonymous_answer: '匿名回答',
    anonymous_comment: '匿名评论',
    reply_detail: '回复详情',
    join_now: '立即加入',
    enter_circle: '进入圈子',
    sure_agree_join: '确定同意加入吗？',
    please_enter_topic_name: '请输入话题名称',
    max_choose_topic: '最多选择{0}个',
    group: {
      cover: '圈子封面',
      cover_tip: '建议尺寸130*130，文件不超过1MB的jpg、jpeg、png图片',
      please_upload_select: '请上传或选择封面',
      name: '圈子名称',
      please_enter_name: '请输入圈子名称',
      intro: '圈子简介',
      intro_p: '请输入圈子简介',
      owner: '圈主简介',
      owner_p: '请输入圈主简介',
      access_permission: '访问权限',
      join_limit: '加入限制',
      browse_settings: '浏览设置',
      post_subject_permission: '发布主题权限',
      comment_reply_permission: '评论回答回复权限',
      member_info: '成员信息',
      anonymous_settings: '匿名设置',
      permission_settings: '权限设置',
      allow_anonymous_post_subject: '允许匿名发布主题',
      allow_anonymous_comment_answer_reply: '允许匿名评论/回答/回复',
      allow_anonymous_all: '允许匿名评论/回答/回复',
      no_anonymity_allowed: '不允许匿名',
      not_save_leave: '内容未保存，是否退出',
      created_successfully: '创建圈子成功，跳转中..."',
      created_failed: '创建圈子失败',
      public_circle: '公开圈子',
      public_circle_tip: '所有用户均可访问',
      private_circle: '私密圈子',
      private_circle_tip: '不在发现圈子中展示',
      no_need_review: '无需审核',
      no_need_review_tip: '所有用户允许加入',
      need_review: '需要审核',
      need_review_tip: '所有用户加入圈子需要审核',
      specify_user_to_join: '指定用户加入',
      specify_user_to_join_tip_2: '其他用户无法申请加入',
      specify_user_to_join_tip: '只能圈子和圈子管理员邀请加入',
      allow: '允许未加入用户预览部分主题',
      allow_tip: '勾选后，允许未加入用户浏览三篇精华或最新发布的主题',
      not_allowed: '不允许',
      not_allowed_tip: '不允许未加入用户浏览分主题',
      all_people: '所有人',
      circle_owner_only: '仅圈主',
      owner_admin: '圈主+管理员',
      open_member_list: '开放成员列表',
      open_member_list_tip: '勾选后，在圈子主页展示成员列表',
      closed_member_list: '不开放成员列表',
      allow_anonymity: '允许匿名',
      contact_owner_join_circle: '你还未加入该圈子且该圈子是私密圈子，请联系圈主加入圈子！',
      the_circle_is_created: '恭喜您，圈子创建好了！',
      the_circle_is_created_tip: '在这里，可邀请成员加入您的圈子',
      the_circle_is_created_tip_2: '作为圈主，请发布本圈子的第一个主题吧',
    },
    addpost: {
      title: '标题',
      please_enter_title: '请输入标题',
      title_less_tip: '标题不得少于{0}个字',
      content: '内容',
      text: '正文',
      please_enter_text: '请输入正文',
      text_less_tip: '正文不得少于{0}个字',
      text_more_tip: '正文不得大于{0}个字',
      link: '链接',
      allow_download: '允许下载(仅PC端支持)',
      upload_cover: '上传封面',
      upload_cover_tip: '建议尺寸：260*150；大小不超过1M',
      upload_image: '上传图片',
      select_circle: '选择圈子(必填)',
      reward_points: '悬赏积分',
      post_anonymously: '匿名发贴',
      add_link: '添加链接',
      please_enter_link: '请输入链接',
      link_start: '链接请以http://或者https://开头',
      link_tip: '请注意，您正在分享一个外部链接，所有成员在第三方网站提交的信息将由该网站依据其隐私、保密和安全条款执行并负责。',
      select_bonus_points: '选择悬赏积分',
      please_enter_reward_points: '请输入悬赏积分',
      available_points: '可用积分',
      select_image: '选择图片',
      select_video: '选择视频',
      content_less_tip: '内容不得少于{0}个字',
      post: '发帖子',
      ask: '提问题',
      article: '写文章',
      failed_get_data: '获取数据失败',
      url_format_incorrect: 'Url格式不正确',
      topic: '话题',
      select_topic: '选择话题',
      click_enter_create_topic: '，或点击回车新建话题',
      choose_up_topics: '最多选择{0}个话题',
      this_topic_is_selected: '已选择此话题',
      less_word_topic: '话题不得少于{0}个字',
      input_positive_integer: '请输入正整数',
      insufficient_points: '积分不足',
      circle: '圈子',
      please_select_circle: '请选择圈子！',
      published_successfully: '发布成功',
      publish_failed: '发布失败',
      choose_person: '选择人员',
      please_choose_person: '请选择人员',
      invite_up_to_nth_people: '最多邀请{0}位',
      picture_tip: '己上传{0}张，最多上传{1}张，支持jpg、jpeg、png、gif格式，每张不超过{2}',
      video_tip: '请上传{0}以下的视频 | 支持上传MP4、AVI、MOV、WMV、RM(VB)、FLV格式',
      file_tip: '己上传{0}个，最多上传{1}个，支持pdf、ppt、pptx、doc、docx（单个文档小于100MB）、zip（单个附件小于1G）',
      parse: '解析',
      delete_link_tip: '确认删除链接吗？',
      save_draft: '存草稿',
    },
    not_open_join: '该学习圈暂未开放加入！',
    sure_join: '确定申请加入吗？',
    application_successful: '申请成功！',
    application_failed: '申请失败！',
    has_application_wait: '已申请成功，请耐心等待审核！',
    nth_people: '...等{0}人',
    click_download: '点击下载',
    add_post: '发帖子',
    add_ask: '提问题',
    add_article: '发文章',
    not_post_tip: '内容发布功能已被关闭，如需使用，请与管理员联系！',
    nth_answer: '{0}个回答',
    confirm_cancel: '确认取消',
    confirm_cancel_tip: '当前操作尚未保存，是否确认取消？',
    invite_members: '邀请成员',
    invite_members_tips: '请输入姓名或者账号进行邀请',
    agree_to_join: '同意加入',
    have_applied: '已申请',
    best_sharer: '最佳分享者',
    related_osts: '相关帖子',
    member_list: '成员列表',
    please_enter_account_name_department_to_search: '请输入账号/姓名/部门搜索',
    batch_remove: '批量移除',
    remove_selected_nth_people: '确定要移除选中的{0}人吗',
    click_answer: '点击回答',
    click_comment: '点击评论',
    toggle_follow: '关注 | 取消关注',
    toggle_like: '点赞 | 取消点赞',
    toggle_collect: '收藏 | 取消收藏',
    all_comments: '全部评论',
    all_answers: '全部回答',
    by_time: '按时间',
    mentioned: '提到了',
    nth_replies: '{0}条回复',
    hot_topic: '热门话题',
    are_you_sure_to_delete: '确认删除吗？',
    post_count: '帖子数',
    quesition_count: '问题数',
    article_count: '文章数',
  },
  hot_sign_up: '火热报名',
  loading: '数据加载中...',
  has_send_to: '已发送至',
  login_successful: '登录成功',
  login_failed: '登录失败',
  login_failed_not_admin: '登录失败；账号未开通管理权限',
  confirm_login_you: '请确认使用本人账号登录',
  login_confirm: '登录确认',
  sorry: '抱歉',
  page_not_exist: '您访问的页面不存在',
  update_tip: '更新提示',
  update_content: '新版本已经准备好，是否重启应用？',
  new_version: '已经有新版本了哟',
  new_version_content: '新版本已经上线啦~，请您删除当前小程序，重新搜索打开哟~',
  all_task: '全部任务',
  cm_pre: '上一页',
  cm_next: '下一页',
  face: {
    pending_face: '待完成面授',
    history_face: '历史面授',
    please_enter: '请输入面授名称',
    type: '面授类型',
    state: '面授状态',
    online: '线上授课',
    offline: '线下授课',
    mix: '混合授课',
    study_state: '学习状态',
    face_time: '面授时间',
    address: '培训地址',
  },
  view_detail: '查看详情',
  no_more: '没有更多了',
  load_more: '加载更多',
  please_select_data: '请选择数据',
  issued_independently: '独立下发',
  teaching: {
    helping_task: '帮带任务',
    learning_track: '学习跟踪',
    table: {
      map_name: '学习地图名称',
      schedule_completion: '安排计划完成',
      evaluate_students: '评价学员完成',
      summarize: '总结完成',
      operate: '操作',
      name_account: '姓名（账号）',
      department: '部门',
      resource_name: '资源名称',
      status: '状态',
      completion_standard: '完成标准',
      finish: '完成',
      in_progress: '进行中',
      overdue: '逾期',
      completion_rate: '完成率',
      pass_rate: '通过率',
      participation_rate: '参与率',
      name: '姓名',
      account: '账号',
      position: '岗位',
      whether_to_pass: '是否通过',
      learning_progress: '学习进度',
      study_completed: '学习完成',
      pass_num: '及格场次',
      online_study_time: '线上学习时长',
      激活时间:'激活时间',
      到期时间:'到期时间',
      exam_time: '考试用时',
      stage_progress: '阶段进度',
    },
    arranged: '已安排',
    should_arrange: '应安排',
    rated: '已评价',
    should_evaluate: '应评价',
    should_submit: '应提交',
    arrange_plan: '安排计划',
    evaluate_students: '评价学员',
    review_summary: '批阅总结',
    please_enter_name_account: '请输入姓名/账号',
    not_arranged: '未安排',
    pending_feedback: '待反馈',
    arrange_a_helping_program: '安排帮带计划',
    mentor_program: '导师计划',
    arrange_help_tip: '学员反馈：学员已确认',
    arrange_help_input_tip: '请输入您对该学员安排的帮带计划，例如希望学员在帮带期间应参与的培训课程，应掌握和提高的业务技能、能力，应承担的工作，预计达成的目标等',
    excellent: '优秀',
    qualified: '合格',
    unqualified: '不合格',
    submit_summary_complete: '提交总结完成',
    approved_qualified: '批阅合格完成',
    summary_review: '总结批阅',
    require: '总结要求',
    total_score: '总结总分',
    passing_score: '合格总分',
    submitter: '提交人',
    summary_content: '总结内容',
    please_enter_a_comment: '请输入评语',
    fill_score: '满分{0}',
    num_score_pass: '{0}分合格',
    overdue: '逾期',
  },
  cm_view: '查看',
  demand: {
    call_for_infor: '需求征集信息',
    please_enter_the_name_of_the_demand_collection: '请输入需求征集名称',
    table: {
      solicitation_name: '需求征集名称',
      solicitation_year: '征集年度',
      collection_time: '征集时间',
      status: '状态',
      operate: '操作',
    },
    call_for_requests: '征集要求',
    add_demand: '新增需求',
    collection_has_not_started_yet: '征集未开始',
    solicitation_has_ended: '征集已结束',
    submit_record: '提交记录',
    form: {
      year: '年度',
      training_name: '需求名称',
      training_name_p: '请输入需求名称',
      training_form: '培训形式',
      training_form_p: '请选择培训形式',
      holding_time: '举办时间',
      holding_time_p: '请选择举办时间',
      requirements_catalog: '需求目录',
      requirements_catalog_p: '请选择需求目录',
      training_budget: '预算费用',
      training_budget_p: '请输入培训预算费用',
      content_of_training_needs: '培训需求内容',
      content_of_training_needs_p: '请输入培训需求内容',
      reason_for_training_need: '培训需求理由',
      reason_for_training_need_p: '请输入培训需求理由',
    },
    requirement_reporting_record: '需求上报记录',
    internal_training: '内部培训',
    send_out_training: '派出培训',
    add_save_tip: '提交成功，是否继续提交需求？',
    batch_reporting: '批量上报',
    batch_delete: '批量删除',
    budget: '预算费用',
    report_tip: '请选择您要上报的需求',
    sure_repors: '确定要批量上报选中的需求数据吗？',
    sure_repor: '确定要上报该需求数据吗？',
    del_tip: '请选择您要删除的需求',
    sure_dels: '确定要批量删除选中的需求数据吗？',
    sure_del: '确定要删除该需求数据吗？',
    report_status: '上报状态',
    reporting_time: '上报时间',
    reported: '已上报',
    not_reported: '未上报',
    requirement_information: '需求信息',
    reporting_information: '上报信息',
    develop_information: '制定信息',
    reportperson: '上报人',
    established: '已制定',
    not_formulated: '未制定',
    set_time: '制定时间',
    training_plan_name: '培训计划名称',
    reporting_department: '上报部门',
    reporting: '上报',
    yuan: '元',
    please_enter_name: '请输入名称（50字以内）',
    please_enter_requirements: '请输入培训需求内容（500字以内）',
    please_enter_reason: '请输入培训需求理由（500字以内）',
    please_enter_budget_cost: '请输入预算费用<99999',
  },
  overdue: '逾期',
  cm_add: '新增',
  cm_operate: '操作',
  confirm_prompt: '确认提示',
  hottest: '最热',
  current_position: '当前位置',
  please_enter_keywords: '请输入关键字',
  cm_refresh: '刷一刷',
  sorry_you_visited: '抱歉，您访问的{0}',
  cm_door: '门',
  cm_field: '场',
  sure_submit: '确定提交吗？',
  no_attachment: '暂无附件',
  knowledge: {
    knowledge_catalog: '知识库目录',
    no_catalog: '暂无目录',
    knowledge_base_of: '知识库情况',
    total_knowledge: '共有 | 个知识',
    knowledge_content: '知识库内容',
    pic: '图片',
    big_pic: '大图',
    list: '列表',
    intro: '知识简介',
    sharer: '分享人员',
    table: {
      file_name: '知识库名称',
      file_type: '文件类型',
      downloads: '下载量',
      likes: '获赞量',
      views: '浏览量',
      comments: '评论数',
      sharer: '分享人',
      upload_time: '上传时间',
    },
    no_exist: '抱歉，您访问的知识不存在。',
  },
  the_version_is_too_low_please_upgrade: '版本过低请升级',
  cm_views: '浏览量',
  cm_account: '账号',
  cm_modify: '修改',
  cm_start: '开始',
  cm_end: '结束',
  system_prompt: '系统提示',
  pic: '图',
  cm_ok: '好的',
  teacher: {
    calendar: '日程表',
    online_courses: '线上课程',
    offline_class_schedule: '线下授课表',
    live_class_schedule: '直播课程表',
    lecturer_file: '讲师档案',
    lecturer_application_record: '讲师申请记录',
    student_area: '学员专区',
    total_today_nth_lesson: '今日共 | 节课',
    banner_tip: '企业内训讲师资源，可以快速了解企业师资力量',
    lecturer_infor: '讲师信息',
    application_record: '申请记录',
    historical_tenure: '历史聘期',
    honor: '荣誉',
    avatar_tip: '图片上传比例为3:4，最佳上传分辨率300*400',
    lecturer_level: '讲师等级',
    lecturer_level_p: '请选择讲师等级',
    name: '姓名',
    lecturer_classification: '讲师分类',
    lecturer_classification_p: '请选择讲师分类',
    sex: '性别',
    man_woman: '男 | 女',
    mobile: '手机号',
    email: '邮箱',
    tel: '座机号',
    position: '职务',
    birthday: '出生日期',
    inside: '内部',
    external: '外部',
    honor_description: '荣誉描述',
    lecturer_intro: '讲师简介',
    lecturer_intro_p: '请输入讲师简介',
    motto: '座右铭',
    motto_p: '请输入座右铭',
    teachingDirection: '授课方向',
    teachingDirection_p: '请输入授课方向',
    expertAreas: '擅长领域',
    expertAreas_p: '请输入擅长领域',
    expertTitle: '专家称号',
    add_title: '添加称号',
    researchAreas: '研究领域',
    researchAreas_p: '请输入研究领域',
    workExperience: '工作简历',
    workExperience_p: '请输入工作简历',
    others: '其它',
    others_p: '请输入其他',
    upload_avatar: '上传头像',
    crop: '裁切',
    under_review: '审核中',
    rejected: '已驳回',
    apply_level: '申请等级',
    attachment: '课件附件',
    apply_status: '申请状态',
    apply_time: '申请时间',
    take_effect: '生效',
    edit: '聘期修改',
    newly_hired: '新聘',
    renew: '续聘',
    promotion: '晋升',
    downgrade: '降级',
    fired: '解聘',
    honor_time: '荣誉时间',
    please_enter_a_certificate_name: '请输入证书名称',
    review_rejected: '审核驳回',
    review_pass: '审核通过',
    assessment_level: '评定等级',
    result: '聘期结果',
    evaluation_date: '评价日期',
    department: '所属部门',
    unit: '所属单位',
    lecturer_courseware: '讲师课件',
    lecturer_courseware_p: '请上传课件',
    lecturer_application_is_under_review_please_be_patient: '当前讲师申请正在审核中，请耐心等待...',
    lecturer_application_information_audit_rejected_please_again: '讲师申请信息审核驳回，请重新',
  },
  not_over: '未结束',
  PracticeResults: "对练结果",
  PracticeDuration: "对练用时",
  PracticeStatus: "对练状态",
  ResultAnalysis: "结果分析",
  MonitoringDetails: "监控详情",
  AnalysisRadar: "分析雷达",
  dialogue_review: '对话回顾',
  cm_customer: '客户',
  cm_sales: '销售',
  cm_face_matching: '人脸匹配',
  cm_are_you_serious: '是否认真',
  cm_yes: '是',
  cm_no: '否',
  chapter_completion: '章节完成',
  homework_after_class: '课后作业',
  after_class_exam: '课后考试',
  after_class_assessment: '课后评估',
  class_assessment: '课程评估',
  star_review: '星评',
  cm_learning_data: '学习数据',
  cm_entry_time: '入职时间',
  cm_export: '导出',
  learningArchive: {
    StudyArchives: '的学习档案',
    TotalNumberOfLogins: "登录总次数",
    OnlineDuration: "在线时长",
    NumberOfSelfStudyCourses: "自学课程数",
    NumberOfTrainingPrograms: "培训项目",
    SelfStudyCourse: "自学课程",
    table: {
      serial: '序号',
      courseName: '课程名称',
      courseDuration: '课程时长(分)',
      learningDuration: '学习时长(分)',
      studyProgress: '学习进度',
      startStudyTime: '开始学习时间',
      finalStudyTime: '最后学习时间',
    },
  },
  cm_ge: '个',
  downloadSuccessfully: '下载成功',
  networkErrMsg: '检测到当前您的网络环境比较差，继续学习会影响学习记录的统计，建议您退出学习界面！',
  getScoreWayTip: '获得积分规则',
  ScoreTimeWayTip: '按合计次数给分 通过一次获得{scores}积分，合计最多{times}次可获得积分',
  ScoreTimeWayTip2: '按合计次数给分 通过一次获得{scores}积分，不限次数',
  ScoreDayWayTip: '按每日次数给分 每日通过一次获得{scores}积分，每日最多{times}次可获得积分',
  ScoreDayWayTip2: '按每日次数给分 每日通过一次获得{scores}积分，不限次数',
  ScoreNumberWayTip: '按答对个数给分 每答对一题获得{scores}积分',
  FaceToFaceTeachingCannotBeEvaluated: '面授还未开始，不能进行评估',
  downloadCenter: '下载中心',
  PleaseEnterFileName: '请输入文件名',
  downloadCenterTips: '文件正在生成中，请稍后前往下载中心下载',
  expiresAfter3Days: "（3天后失效）",
  generating: "生成中",
  LastPage: "上一页",
  NextPage: "下一页",
  StartDownloading: "开始下载",
  InTotal: "共{total}条",
  LB_Error_ExamHasTraining: "后开始培训",
  TrainingHasEnded: "培训已结束",
  TrainingInProgress: "培训进行中",
  total_class_hours: "总课时",
  remainder: '剩余',
  expanded: '展开',
  unexpanded: '收起',
  expandedAll: '展开所有',
  unexpandedAll: '收起所有',
  informationCollection: '信息收集',
  charactersOrLess: '({0}字以内)',
  IdCard: "身份证",
  passport: "护照",
  TaiwanPass: "台湾通行证",
  HongKongMacaoPass: "港澳通行证",
  city: "城市",
  ActivityInformationCollection: '活动信息收集',
  ExamInformationCollection: '考试信息收集',
  ParticipationInExams: '参与考试',
  FileNotPreview: '{type}文件不支持预览',
}